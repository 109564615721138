import React from "react";

export default function Input({
  onChange,
  value,
  label,
  type,
  name,
  placeholder,
}) {
  return (
    <div className="flex items-start gap-2 flex-col w-full">
      <label
        htmlFor={label}
        className="text-base font-medium text-[#333] capitalize"
      >
        {label}
      </label>

      <input
        type={type ? type : "text"}
        name={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className="p-3 outline-none border w-full border-[#ccc] rounded-lg text-black"
      />
    </div>
  );
}
