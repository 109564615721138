import React from "react";
import classes from "./distribute.module.css";
import { MdComputer } from "react-icons/md";
import { FiLink } from "react-icons/fi";
import { VscGraph } from "react-icons/vsc";

export default function DoMore() {
  return (
    <section className={classes.more}>
      <h1>Do More with Distribute</h1>
      <div className={classes.wrapper}>
        <div className={classes.wrap}>
          <span>
            <MdComputer />
          </span>
          <h3>Create Landing Pages</h3>
          <p>
            Create web pages for your business, make your business vvisible
            online.
          </p>
        </div>
        <div className={classes.wrap}>
          <span>
            <MdComputer />
          </span>
          <h3>Digitize your Operations</h3>
          <p>
            Tired of carrying pen and paper all around? with Distribute do
            everything on your pc or phone.
          </p>
        </div>
        <div className={classes.wrap}>
          <span>
            <VscGraph />
          </span>
          <h3>Increase and Manage Sales</h3>
          <p>
            Reach new clients and customers, Get ,manufacturers warehouses and
            logistics brands to help you with your goods.
          </p>
        </div>
        <div className={classes.wrap}>
          <span>
            <FiLink />
          </span>
          <h3>Connect with Salesplat</h3>
          <p>
            Link your Distribute account with Groundforce, Reccords, Affils...
            Sit back and let the work do itself.
          </p>
        </div>
      </div>
    </section>
  );
}
