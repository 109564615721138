import React from "react";
import user from "../../images/groundforce-dashboard/Avatar (1).png";

import { FiEdit2 } from "react-icons/fi";

export default function CustomerItem() {
  return (
    <div className="w-full flex items-center justify-between p-3">
      <div className="w-[30%] flex items-center gap-2">
        <div className="w-[2.4rem]">
          <img className="img h-[2.5rem] rounded-full" src={user} alt="user" />
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-sm font-semibold capitalize text-[#101828]">
            Dianne Russell
          </p>
          <span className="font-normal text-xs capitalize text-[#667085]">
            vuhaithuongnute@gmail.com
          </span>
        </div>
      </div>
      <div className="hidden lg:block w-[30%]">
        <p className="p-2 w-[150px] text-center rounded-2xl border-2 border-black capitalize">
          product A
        </p>
      </div>
      <div className="hidden lg:block w-[30%] p-2">
        <p className=" capitalize">Customer</p>
      </div>
      <div className="lg:flex-1 p-2">
        <p className="stat-arrow">
          <FiEdit2 />
        </p>
      </div>
    </div>
  );
}
