import React from "react";
import user from "../../images/groundforce-dashboard/Avatar (1).png";

export default function ActivityItem() {
  return (
    <div className="w-full flex items-center p-2 ">
      <div className="flex-1 flex items-center gap-2">
        <div className="w-[2.4rem]">
          <img className="img h-[2.5rem] rounded-full" src={user} alt="user" />
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-sm font-semibold capitalize text-[#101828]">
            Promise Tunde
          </p>
          <span className="font-normal text-xs capitalize text-[#667085]">
            Purchased Product A
          </span>
        </div>
      </div>
      <p className="p-1">1m</p>
    </div>
  );
}
