import React from "react";
import GroundForce from "../../components/Groundforce/GroundForce";

export default function Groundforce() {
  return (
    <>
      <GroundForce />
    </>
  );
}
