import React, { Fragment } from "react";
import ManageYourBusiness from "../Groundforce/ManageYourBusiness";
import image1 from "../../images/distribute/Rectangle 5 (1).png";
import image2 from "../../images/distribute/Rectangle 5.png";
import Distribution from "./Distribution";
import GetGood from "./GetGood";
import DoMore from "./DoMore";
import FAQs from "../UI/FAQs";
import Expand from "../UI/Expand";
import Footer from "../Footer";
import Navbar from "../Navbar";

export default function Distribute() {
  return (
    <Fragment>
      <Navbar style={{ backgroundColor: "#FFF3D3" }} />
      <ManageYourBusiness
        title="Bridging the gap between your products and your consumers"
        text="Whether warehouses or logistics.... Distribute gives you the vehicles to get to your customers"
        image1={image1}
        image2={image2}
        btntext="use distribute"
        style={{ backgroundColor: "#FFF3D3" }}
      />
      <Distribution />
      <GetGood />
      <DoMore />
      <FAQs />
      <Expand title="distribute" text="Reach your costumers" />
      <Footer />
    </Fragment>
  );
}
