import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { FiArrowDown } from "react-icons/fi";

import user from "../../images/groundforce-dashboard/Avatar.png";

export default function TotalAgents() {
  return (
    <div className="stat-wrap lg:w-[30%]">
      <div className="stat-header">
        <h1 className="stat-title">Total agents</h1>
        <span className="stat-arrow text-black">
          <FiArrowUpRight />
        </span>
      </div>
      <div className="w-full flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <h1 className="stat-value">316</h1>
          <div className="flex items-center gap-2">
            <span className="stat-arrow text-[#B42318]">
              <FiArrowDown />
            </span>
            <span className="text-sm font-normal text-[#B42318]">
              200 active
            </span>
          </div>
        </div>
        <div className="w-[40%] flex items-center mt-16">
          <div className="w-[2rem]">
            <img className="img" src={user} alt="stat" />
          </div>
          <div className="w-[2rem] ml-[-0.6rem]">
            <img className="img" src={user} alt="stat" />
          </div>
          <div className="w-[2rem] ml-[-0.6rem]">
            <img className="img" src={user} alt="stat" />
          </div>
          <div className="w-[2rem] ml-[-0.6rem]">
            <img className="img" src={user} alt="stat" />
          </div>
          <div className="w-[2rem] ml-[-0.6rem]">
            <img className="img" src={user} alt="stat" />
          </div>
          <div className="w-[3rem] bg-[#F9F5FF] ml-[-0.6rem] flex items-center justify-center rounded-full h-[2rem]">
            <span className="text-base font-medium text-[#7F56D9]">+195</span>
          </div>
        </div>
      </div>
    </div>
  );
}
