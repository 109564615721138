import React from "react";
import ActivityItem from "./ActivityItem";

export default function ActivityList() {
  const data = [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ];
  return (
    <div className="w-full max-h-[50vh] lg:max-h-[80rem] overflow-scroll flex flex-col">
      {data.map((item, i) => (
        <ActivityItem item={item} key={i} />
      ))}
    </div>
  );
}
