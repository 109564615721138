import React from "react";
import classes from "./incubate.module.css";
import learners from "../../images/Incubate/image 5.png";
import learnerssmall from "../../images/Incubate/learnerssmall.png";
const Learners = () => {
  return (
    <div className="w-[90%] lg:w-[80%] mx-auto flex flex-col md:flex-row gap-4 py-6 lg:gap-0 lg:flex-row">
      <div className="w-[90%] p-4 lg:h-[420px] lg:w-[55%] mx-auto items-center justify-center  flex flex-col gap-3">
        <h2 className="text-[30px] font-bold text-center text-black">
          Incubate for Learners
        </h2>
        <p className="text-base w-[80%] mx-auto text-center">
          Advance your Sales and Business Growth Skills, Kickstart a career in
          Revenue and customer focused Roles with courses and BootCamps
        </p>
        <button className="p-3 rounded-lg hover:opacity-80 hover:shadow-lg text-white font-bold bg-[#0d5ba1]">
          Get Started Right Away
        </button>
      </div>
      <div className="w-[90%] mx-auto lg:w-[40%]">
        <img
          alt="learn description"
          src={learners}
          className={classes.largeScreen}
        />
        <img
          alt="learn description"
          src={learnerssmall}
          className={classes.smallScreen}
        />
      </div>
    </div>
  );
};

export default Learners;
