import React from "react";
import CustomerItem from "./CustomerItem";

export default function CustomerList() {
  const data = [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ];
  return (
    <div className="w-full max-h-[50vh] lg:max-h-[79rem] overflow-scroll flex flex-col">
      {data.map((item, i) => (
        <CustomerItem item={item} key={i} />
      ))}
    </div>
  );
}
