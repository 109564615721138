import React from "react";
import classes from "./affil.module.css";

import { MdComputer } from "react-icons/md";
import { HiOutlineBookOpen } from "react-icons/hi";
import { FaHammer } from "react-icons/fa";
import { FaCarrot } from "react-icons/fa";
import { FiCodesandbox } from "react-icons/fi";
import { CiWavePulse1 } from "react-icons/ci";

function ReusableSellPproduct({ title, text, icon }) {
  return (
    <div className="lg:w-[30%] my-3 md:w-[45%] w-[90%] flex flex-col items-center gap-4 p-2">
      <span className="text-[#333] text-[2.5rem]">{icon}</span>
      <h3 className="text-[#2d2d2d] font-bold text-base lg:text-2xl">
        {title}
      </h3>
      <p className="text-sm w-[80%] mx-auto lg:text-base text-[#2d2d2d] text-center">
        {text}
      </p>
    </div>
  );
}

export default function SellProduct() {
  return (
    <section className={classes.sell}>
      <h1>Sell your product or service anywhere, anytime...</h1>
      <div className="flex items-center justify-center lg:justify-between flex-wrap">
        <ReusableSellPproduct
          title="Digital Products"
          text="Sell any type of digital products , courses... without hassle or
        stress"
          icon={<MdComputer />}
        />

        <ReusableSellPproduct
          title="Health and Fitness"
          text="Use Affils to sell your health and fitness products to anyone
        anywhere."
          icon={<CiWavePulse1 />}
        />

        <ReusableSellPproduct
          title="Agri-products"
          text="Affils can help boost up your Agricultural products sales to
        customers everywhere."
          icon={<FaCarrot />}
        />

        <ReusableSellPproduct
          title="E-Books"
          text="Affils is the best platform to sell your E-books in any format."
          icon={<HiOutlineBookOpen />}
        />

        <ReusableSellPproduct
          title="Services"
          text="You can sell any type of service you render on Affils from teaching
        to design..."
          icon={<FaHammer />}
        />

        <ReusableSellPproduct
          title="Physical Products"
          text="Sell your physical goods and products to your customers. far and
        near"
          icon={<FiCodesandbox />}
        />
      </div>
    </section>
  );
}
