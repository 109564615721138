import { Flex, Image, Box, Button } from "@chakra-ui/react";
import logo from "../../images/incubatelogo.png";
import { NavLink } from "react-router-dom";

export default function IncubateHeader() {
  return (
    <Flex
      borderBottom="1px solid #666"
      w="100%"
      align="center"
      px="2.5rem"
      py="1rem"
      justify="space-between"
    >
      <Box w="127px">
        <Image
          src={logo}
          alt="incubate logo"
          boxSize="100%"
          objectFit="cover"
        />
      </Box>
      <Flex
        display={{ lg: "flex", md: "flex", base: "none" }}
        align="center"
        gap="1rem"
      >
        <NavLink to="" className="incubate-menu">
          For Learners
        </NavLink>
        <NavLink to="" className="incubate-menu">
          For Businesses and organizations
        </NavLink>
        <NavLink to="" className="incubate-menu">
          For Mentors
        </NavLink>
        <NavLink to="" className="incubate-menu">
          Programs
        </NavLink>
        <NavLink to="" className="incubate-menu">
          FAQs
        </NavLink>
      </Flex>
      <Button
        p="0.8rem"
        borderRadius="0.75rem"
        size="sm"
        variant="solid"
        color="white"
        bg="#4884DF"
      >
        Start Learning
      </Button>
    </Flex>
  );
}
