import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";

// const theme = extendTheme({
//   colors: {
//     brand: {
//       //   100: "#C395BB",
//       900: "#4884DF",
//     },
//     gray: {
//       100: "#B2B3B3",
//       900: "#828282",
//     },
//     success: {
//       100: "#90EE90",
//       900: "#00AC45",
//     },
//     error: {
//       100: "#ffcccb",
//       900: "#FF3436",
//     },
//     warning: {
//       100: "#FFB649",
//       900: "#FFB649",
//     },
//   },
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
//render app to root

root.render(
  <>
    <App />
    <ToastContainer />
  </>
);
