import React from "react";
import Header from "../Header";
import ProductHeader from "./ProductHeader";
import ProductList from "./ProductList";

export default function Product() {
  return (
    <div className="w-full">
      <Header />
      <ProductHeader />
      <ProductList />
    </div>
  );
}
