import React from "react";
import classes from "./groundforce.module.css";
import { FaPeopleArrows } from "react-icons/fa";
import { SiPivotaltracker } from "react-icons/si";

function ReusableMarketExpansion({ title, text, icon }) {
  return (
    <div className="w-[90%] mx-auto md:w-[25%] lg:w-[30%] flex flex-col items-center gap-4">
      <span className="w-[10rem] h-[10rem] rounded-full bg-[#ddfffe] flex items-center justify-center text-[4rem]">
        {icon}
      </span>
      <h3 className="text-xl capitalize font-medium text-black text-center">
        {title}
      </h3>
      <p className="text-base font-normal text-center capitalize">{text}</p>
    </div>
  );
}

export default function MarketExpansion() {
  return (
    <section className="w-full bg-white lg:p-10">
      <h2 className="text-xl lg:text-2xl font-bold text-black lg:w-[60%] mx-auto text-center md:w-[60%] w-[80%]">
        Market expansion and penetration, Inventory and resources management,
        Brand awareness...
      </h2>
      <div className={classes.container}>
        <ReusableMarketExpansion
          title="Acquire Qualified agents"
          text="Hire sufficient salespersons and field agents to help you distribute
        your goods and services anywhere across Africa"
          icon={<FaPeopleArrows />}
        />

        <ReusableMarketExpansion
          title="Track And manage Agents "
          text="Track the performance, Location, Distribution, Productivity of your
        agents and manage them without stress."
          icon={<SiPivotaltracker />}
        />

        <ReusableMarketExpansion
          title="Enhance Accountability and Productivity  "
          text="Communicate with your agents, recieve detailed, regular field,
        market reports to scale your business or brand."
          icon={<SiPivotaltracker />}
        />
      </div>
    </section>
  );
}
