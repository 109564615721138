import React from "react";

import classes from "../../styles/gotback.module.css";
import first from "../../images/Tested/Rectangle 55.png";
import second from "../../images/Tested/Rectangle 56.png";
import third from "../../images/Tested/Rectangle 45.png";

function ReusableGotBack({ image, title, btnText }) {
  return (
    <div className="w-[90%] md:w-[45%] mx-auto lg:w-[30%] my-3 relative">
      <div className="relative w-full">
        <img
        style={{
          borderTopLeftRadius: "2rem",
          borderBottomRightRadius: "2rem",
        }}
          className="w-full  object-cover md:h-[400px] h-[280px] lg:h-[400px]"
          alt="visual description"
          src={image}
        />
      </div>
      <div className="absolute bottom-[10%] p-4 flex flex-col gap-2">
        <p className="text-base text-white font-medium">{title}</p>
        <button className="text-white p-3 capitalize rounded-lg bg-transparent border-[#eee] border-2">
          {btnText}
        </button>
      </div>
    </div>
  );
}

const GotBack = () => {
  return (
    <div className="lg:w-[80%] w-[90%] py-10 flex flex-col gap-4 mx-auto">
      <h3>We’ve Got your back</h3>

      <div className="w-full flex items-center justify-between flex-wrap">

      <ReusableGotBack image={first} title='Get the help you need, whenever you need it with 24/7 support' btnText='Get SalesPlat Support'/>
      <ReusableGotBack image={second} title='Need help on your SalesPlat Journey? Work with a Salesplat Partner
      not far from you' btnText='Find a SalesPlat Partner'/>

<ReusableGotBack title='Are you a tech or business consultant, who provides solutions to
small businesses?' btnText='Explore our Partner Programs' image={third}/>


      </div>
    </div>
  );
};

export default GotBack;
