import React from "react";
import TotalCustomer from "./TotalCustomer";
import RevenueGenerated from "./RevenueGenerated";
import TotalAgents from "./TotalAgents";

export default function DashboardCenter() {
  return (
    <div className="w-full flex flex-col gap-4 py-10 px-4 lg:px-20">
      <h1 className="text-[1.5rem] lg:text-[2.5rem] capitalize font-bold text-[#1C1B1F]">
        dashboard
      </h1>
      <div className="w-full flex flex-col lg:flex-row items-center justify-between gap-4">
        <TotalCustomer />
        <RevenueGenerated />
        <TotalAgents />
      </div>
    </div>
  );
}
