import React from "react";
import classes from "./reccords.module.css";

// import image from "../../images/groundforce/Frame 191.svg";

export default function ManageBusiness() {
  return (
    <section className={classes.managebusiness}>
      <h1> Manage your Business</h1>
      <button>use Records</button>
    </section>
  );
}
