import React, { useState } from "react";

import "../../styles/Auth/Signup.css";

import Personal from "../../images/Auth/personal.svg";
import Business from "../../images/Auth/business.svg";

const AccountDecision = ({ parentCallback }) => {
  const [personal, setPersonal] = useState(true);
  const [business, setBusiness] = useState(false);
  const [option, setOption] = useState("");

  const handlePersonal = () => {
    if (personal === false) {
      setPersonal(true);
      setBusiness(false);
      setOption("personal");
      parentCallback("personal");
    }
    return option;
  };

  const handleBusiness = () => {
    if (business === false) {
      setBusiness(true);
      setPersonal(false);
      setOption("business");
      parentCallback("business");
    }
  };

  return (
    <div>
      <div className="decision__plug">
        <h5>Choose Account Type</h5>
        <div className="account__types">
          <div
            className={
              personal
                ? "active flex flex-col items-center gap-4"
                : "personal__account flex flex-col items-center gap-2"
            }
            onClick={handlePersonal}
          >
            <img
              className="object-contain"
              src={Personal}
              alt="Personal avatar"
            />
            <p>Personal</p>
          </div>
          <div
            className={
              business
                ? "active flex flex-col items-center gap-4"
                : "business__account flex flex-col items-center gap-2"
            }
            onClick={handleBusiness}
          >
            <img
              className="object-contain"
              src={Business}
              alt="Business avatar"
            />
            <p>Business</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDecision;
