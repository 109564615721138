import React from "react";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";

import image from "../../../images/groundforce-dashboard/Rectangle 1.png";

export default function ProductItem() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/groundforce-products/overview/id`)}
      className="w-full lg:w-[30%] cursor-pointer flex flex-col border rounded-lg"
    >
      <div className="w-full">
        <img className="img" alt="product" src={image} />
      </div>
      <div className="w-full flex p-4 items-center justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-[1.4rem] font-medium capitalize text-[#101828]">
            Product A
          </p>
          <p className="text-[#667085] font-medium text-[1.2rem]">
            Digital Product
          </p>
        </div>
        <span className="stat-arrow p-2 rounded-lg border">
          <BsThreeDotsVertical />
        </span>
      </div>
    </div>
  );
}
