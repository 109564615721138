import React from "react";
import classes from "./incubate.module.css";

// import image from "../../images/groundforce/Frame 191.svg";

export default function Trained() {
  return (
    <section className={classes.trained}>
      <h1>Get Trained!</h1>
      <button>use incubate</button>
    </section>
  );
}
