import React from "react";
import DashChart from "./Chart";

export default function DashboardChart() {
  return (
    <div className="w-full border rounded-lg p-4 flex flex-col gap-4">
      <div className="w-full gap-4 flex flex-col lg:flex-row items-center justify-between">
        <h1 className="text-[#101828] text-[1.2rem] font-semibold capitalize">
          Sales report
        </h1>
        <div className="w-full lg:w-fit flex flex-col gap-2 items-center lg:items-end lg:justify-end">
          <select className="p-2 bg-transparent text-center text-sm w-full lg:w-[150px] rounded-lg font-bold border">
            <option value="all products">All products</option>
          </select>
          <div className="flex items-center justify-between gap-2 lg:gap-4">
            <div className="flex items-center gap-2">
              <span className="w-[0.7rem] h-[0.7rem] rounded-full bg-[#74AEF9]"></span>
              <span className="text-[#667085] font-normal text-xs lg:text-base capitalize">
                Product A
              </span>
            </div>

            <div className="flex items-center gap-2">
              <span className="w-[0.7rem] h-[0.7rem] rounded-full bg-[#A4C9FF]"></span>
              <span className="text-[#667085] font-normal text-xs lg:text-base capitalize">
                Product B
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="w-[0.7rem] h-[0.7rem] rounded-full bg-[#3B79C1]"></span>
              <span className="text-[#667085] font-normal text-xs lg:text-base capitalize">
                Product B
              </span>
            </div>
          </div>
        </div>
      </div>
      <DashChart />
    </div>
  );
}
