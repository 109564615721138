import React from "react";
import classes from "./incubate.module.css";

const Learn = () => {
  return (
    <div className="w-full flex items-center flex-col gap-3 p-6">
      <h2 className="text-base lg:text-[30px] text-[#2d2d2d] font-bold text-center">
        Learn from Experts, Be an Expert.
      </h2>
      <p className="text-base text-center font-normal">
        Position yourself strategically for the future by learning on-demand
        skills.
      </p>
    </div>
  );
};

export default Learn;
