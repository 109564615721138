import React from "react";

import "../../styles/Auth/Side.css";

import Logo from "../../images/logo.svg";
import Vector from "../../images/Auth/sign__vector.svg";

import One from "../../images/Tested/Rectangle 19.png";
import Two from "../../images/Tested/IMG-20210927-WA0027.png";
import Three from "../../images/Tested/image 1.png";
import Four from "../../images/Tested/Rectangle 21.png";
import Five from "../../images/Tested/Rectangle 24.png";
import Six from "../../images/Tested/Rectangle 22.png";

const Side = () => {
  return (
    <div className="hidden bg-[#ddefff] lg:flex flex-col gap-8 w-[50%] mx-auto p-8 h-screen px-16">
      <div className="logo">
        <img src={Logo} alt="Logo avatar" />
      </div>
      <div className="vector">
        <img src={Vector} alt="Vector avatar" />
      </div>
      <div className="side__description">
        <h3>Grow, Be Boundless!</h3>
        <p>
          By signing up you get unique software tools, platforms, top talents
          and field agents to give your products maximum sales.
        </p>
      </div>
      <div className="flex items-center justify-between w-[90%] lg:w-[80%] first-letter:mx-auto">
        <img
          className="object-contain"
          src={One}
          alt="One avatar"
          width={46}
          height={38}
        />
        <img
          className="object-contain"
          src={Two}
          alt="Two avatar"
          width={104}
          height={40}
        />
        <img
          className="object-contain"
          src={Three}
          alt="Three avatar"
          width={36}
          height={36}
        />
        <img
          className="object-contain"
          src={Four}
          alt="Four avatar"
          width={35}
          height={72}
        />
        <img
          className="object-contain"
          src={Five}
          alt="Five avatar"
          width={114}
          height={38}
        />
        <img
          className="object-contain"
          src={Six}
          alt="Six avatar"
          width={57}
          height={36}
        />
      </div>
    </div>
  );
};

export default Side;
