import React from "react";
import classes from "./groundforce.module.css";

import image from "../../images/groundforce/Frame 114.png";
import image1 from "../../images/groundforce/Frame 187.png";
import image2 from "../../images/groundforce/Frame 186.png";

export default function Captain() {
  return (
    <section className={classes.captain}>
      <h1>You are the captain, We are your army...</h1>
      <div className={classes.captainWrapper}>
        <div className={classes.wrapper}>
          <img alt="groundforce description" src={image1} />
        </div>
        <div className={classes.wrapper}>
          <img alt="groundforce description" src={image} />
        </div>
        <div className={classes.wrapper}>
          <img alt="groundforce description" src={image2} />
        </div>
      </div>
    </section>
  );
}
