import React, { Fragment } from "react";
import classes from "./dropdown.module.css";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

import image from "../../../images/Frame 205.svg";

export default function Dropdown({ setShowProduct }) {
  return (
    <Fragment>
      <div onClick={() => setShowProduct(false)} className="overlay"></div>
      <section
        onMouseOver={() => setShowProduct(true)}
        className={classes.section}
      >
        <div className={classes.image}>
          <img alt="dropdown" src={image} />
        </div>
        <div className={classes.container}>
          <Link to="/groundforce" className={classes.item}>
            <div className={classes.title}>
              <h4>
                groundforce <AiOutlineTrademarkCircle />
              </h4>
              <span>
                <BiRightArrowAlt />
              </span>
            </div>
            <p>
              Manage your business, Optimize distribution, Scale your Profit...
            </p>
          </Link>
          <Link to="/affil" className={classes.item}>
            <div className={classes.title}>
              <h4>
                affils <AiOutlineTrademarkCircle />
              </h4>
              <span>
                <BiRightArrowAlt />
              </span>
            </div>
            <p>Set up affiiiate sales for your products and services.</p>
          </Link>
          <Link to="/supportpro" className={classes.item}>
            <div className={classes.title}>
              <h4>Supportpro</h4>
              <span>
                <BiRightArrowAlt />
              </span>
            </div>
            <p>Get the right tools and people with supportpro</p>
          </Link>
          <Link to="/incubate" className={classes.item}>
            <div className={classes.title}>
              <h4>incubate</h4>
              <span>
                <BiRightArrowAlt />
              </span>
            </div>
            <p>Reskill, upskill yourself and your team with incubate</p>
          </Link>
          <Link to="/distribute" className={classes.item}>
            <div className={classes.title}>
              <h4>distribute</h4>
              <span>
                <BiRightArrowAlt />
              </span>
            </div>
            <p>Bridging the gap between your products and your consumers</p>
          </Link>
          <Link to="/reccords" className={classes.item}>
            <div className={classes.title}>
              <h4>reccords</h4>
              <span>
                <BiRightArrowAlt />
              </span>
            </div>
            <p> Manage your cashflow and records, expand your business.</p>
          </Link>
        </div>
      </section>
    </Fragment>
  );
}
