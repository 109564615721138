import React from "react";
import classes from "./affil.module.css";

export default function Affiliate() {
  return (
    <section className={classes.affiliate}>
      <h1>Be an affiliate marketer</h1>
      <p>Sell products to buyers all around the world and get paid.</p>
      <div className={classes.action}>
        <button>make some cash</button>
      </div>
    </section>
  );
}
