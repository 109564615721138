import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function DetailChart() {
  const [chart, setChart] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#23A455"],
    },
    series: [
      {
        name: "series-1",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });
  return (
    <div className="w-full flex flex-col gap-4 border rounded-lg p-4">
      <div className="w-full flex items-center justify-between">
        <h1 className="text-[#101828] text-[1.2rem] font-semibold capitalize">
          Sales report
        </h1>
      </div>
      <Chart
        options={chart.options}
        series={chart.series}
        type="bar"
        width="100%"
        height={300}
      />
    </div>
  );
}
