import React from "react";
import { MdOutlineAdd } from "react-icons/md";

export default function ProductHeader() {
  return (
    <div className="px-4 lg:px-20 py-12 flex flex-col lg:flex-row items-start justify-start gap-4 lg:items-center lg:justify-between">
      <h1 className="text-[1.5rem] lg:text-[2.5rem] font-bold capitalize text-[#1C1B1F]">
        Products
      </h1>
      <button className="flex items-center bg-[#0D5BA1] py-2 px-4 rounded-lg gap-2">
        <span className="stat-arrow text-white">
          <MdOutlineAdd />
        </span>
        <span className="stat-arrow text-white capitalize">new product</span>
      </button>
    </div>
  );
}
