import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function DashChart() {
  const [chart, setChart] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#23A455"],
    },
    series: [
      {
        name: "series-1",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });
  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      width="100%"
      height={300}
    />
  );
}
