import React from "react";
import Header from "./Header";
import DashboardCenter from "./DashboardCenter";
import DashboardContainer from "./DashboardContainer";

export default function Dashboard() {
  return (
    <div className="w-full">
      <Header />
      <DashboardCenter />
      <DashboardContainer />
    </div>
  );
}
