import React, { useState, useCallback } from "react";

import "../../styles/Auth/Signup.css";

import Side from "../../components/Auth/Side";
import { Link } from "react-router-dom";

// Step Components
import AccountDecision from "./AccountDecision";
import Personal from "./Personal";
import Business from "./Business";

const Signup = () => {
  let [position, setPosition] = useState(0);
  const [type, setType] = useState("personal");

  const callback = useCallback((type) => {
    setType(type);
  }, []);

  const steps = [
    {
      name: "Decision Component",
      component: <AccountDecision parentCallback={callback} />,
    },
    {
      name: `${type} account`,
      component: type === "personal" ? <Personal /> : <Business />,
    },
  ];

  const handlePosition = () => {
    setPosition(position + 1);
  };

  return (
    <div className="w-full flex items-center h-full lg:h-screen">
      <Side />
      <div className="p-4 lg:p-2 flex w-full lg:w-[50%] flex-col items-center gap-4 justify-center h-full lg:max-h-screen lg:overflow-scroll">
        <h3 className="text-[28px] text-center text-black font-medium">
          Create your {type} account{" "}
        </h3>
        <p className="text-base font-normal text-[#333] text-center">
          Already have an account?{" "}
          <span className="login">
            <Link to="/login">Log In here</Link>
          </span>
        </p>

        <div className="decision__action">
          {steps[position].component}

          <div className="action__button">
            {position === 0 ? (
              <button
                className="w-full my-6 bg-[#0d5ba1] text-base text-white font-bold p-3 rounded-lg hover:opacity-80 hover:shadow-lg transition-all duration-300"
                onClick={handlePosition}
              >
                Next
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
