import React, { Fragment } from "react";
import classes from "./dropdown.module.css";
import { Link } from "react-router-dom";

import image from "../../../images/Frame 205.png";

export default function ResourcesDropdown({ setShowResources }) {
  return (
    <Fragment>
      <div onClick={() => setShowResources(false)} className="overlay"></div>
      <section
        onMouseOver={() => setShowResources(true)}
        className={classes.sections}
      >
        <div className={classes.image}>
          <img alt="dropdown" src={image} />
        </div>
        <div className={classes.container}>
          <a href="https://blog.mysalesplat.com" className={classes.item}>
            <div className={classes.title}>
              <h4>Blogs</h4>
            </div>
            <p>
              Read expert content to help you grow your business and maximize
              profit
            </p>
          </a>
          <Link to="/videos" className={classes.item}>
            <div className={classes.title}>
              <h4>Videos</h4>
            </div>
            <p>
              Learn everything you need to know about Salesplat and our products
            </p>
          </Link>
        </div>
      </section>
    </Fragment>
  );
}
