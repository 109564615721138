import React from "react";
import ManageYourBusiness from "../Groundforce/ManageYourBusiness";
import Navbar from "../Navbar";
import Business from "./Business";
import Creators from "./Creators";
import FAQs from "./FAQs";
import Learn from "./Learn";
import Learners from "./Learners";
import Trained from "./Trained";
import heroImage from "../../images/Incubate/Rectangle 5.png";
import herosmall from "../../images/Incubate/Rectangle 5 (1).png";

const Incubate = () => {
  return (
    <div>
      <Navbar style={{ backgroundColor: "#DDFFFE" }} />
      <ManageYourBusiness
        title="Boost Growth and Revenue, Get Sales, RevOps and Growth Trainings on Incubate"
        text="We provide customer-facing and Revenue-driving Talents with the best knowledge and expertise in their Industries and Niche"
        btntext="Start Learning on Incubate "
        image1={heroImage}
        image2={herosmall}
        style={{ backgroundColor: "#DDFFFE" }}
        link="incubate-dashboard"
      />
      <Learn />
      <Learners />
      <Business />
      <Creators />
      <FAQs />
      <Trained />
    </div>
  );
};

export default Incubate;
