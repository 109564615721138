import React, { useState } from "react";

import image1 from "../../images/distribute/Rectangle 63.png";
import image2 from "../../images/distribute/Rectangle 64.png";

import icon1 from "../../images/distribute/Frame 124 (1).png";
import icon2 from "../../images/distribute/Frame 124.png";
import ReusableDistribute from "../UI/reusableDistribute/ReusableDistribute";

export default function Distribution() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  return (
    <ReusableDistribute
      title="Distribution, Transportation and Connection"
      text1="Logistics"
      text2="Warehousing"
      text3="Go online, get new clients, connect with manufacturers,
      distributors, retailers and logistics."
      text4="Connect your warehouses with seeking maufacturers, distributors and retailers. Expand and grow your business, Go Online "
      image1={image1}
      image2={image2}
      icon1={icon2}
      icon2={icon1}
      show={show}
      setShow={setShow}
      show1={show1}
      setShow1={setShow1}
    />
  );
}
