import React from "react";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Overlay from "../UI/Overlay";
import logo from "../../images/groundforce-logo.png";

import { AiOutlineClose } from "react-icons/ai";

export default function MobileMenu({ setShowMenu }) {
  return (
    <Fragment>
      <Overlay closeModal={() => setShowMenu(false)} />
      <div className="w-[80%] z-40 px-4 py-12 lg:hidden h-[100vh] bg-white flex flex-col gap-8 absolute top-0 right-0">
        <span
          onClick={() => setShowMenu(false)}
          className="text-lg text-red-600 cursor-pointer absolute top-4 right-4"
        >
          <AiOutlineClose />
        </span>
        <div className="w-[11rem] mx-auto">
          <img className="img" alt="logo" src={logo} />
        </div>
        <div className="flex flex-col items-start justify-start gap-4">
          <NavLink
            to="/groundforce-dashboard"
            className={(navData) => (navData.isActive ? "active" : "menu")}
          >
            dashboard
          </NavLink>
          <NavLink
            to="/groundforce-products"
            className={(navData) => (navData.isActive ? "active" : "menu")}
          >
            products
          </NavLink>
          <NavLink
            to="/groundforce-activities"
            className={(navData) => (navData.isActive ? "active" : "menu")}
          >
            activities
          </NavLink>
          <NavLink
            to="/groundforce-transactions"
            className={(navData) => (navData.isActive ? "active" : "menu")}
          >
            transactions
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
}
