import React from "react";
import Footer from "../../components/Footer";
import Reccords from "../../components/Reccords/Reccords";


export default function Reccord() {
  return (
    <>
      <Reccords />
      <Footer />
    </>
  );
}
