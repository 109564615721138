import React, { Fragment } from "react";
import classes from "./groundforce.module.css";
import image from "../../images/groundforce/unsplash_WdJkXFQ4VHY.png";
import image1 from "../../images/groundforce/unsplash_WdJkXFQ4VHY (1).png";
import Captain from "./Captain";
import { useNavigate } from "react-router-dom";

export default function Join() {
  const navigate = useNavigate();
  return (
    <Fragment>
      <section className={classes.join}>
        <div className={classes.content}>
          <h2>Join as a Groundforce agent</h2>
          <p>
            Do you have what it takes to be a middle-man? Can you get goods to
            consumers? Are you a salesperson? This is for you.
          </p>
          <button onClick={() => navigate("/groundforce-dashboard")}>
            join groundforce
          </button>
        </div>
        <div className={classes.image}>
          <img
            alt="join description"
            src={image}
            className={classes.largeScreen}
          />
          <img
            alt="join description"
            src={image1}
            className={classes.smallScreen}
          />
        </div>
      </section>
      <Captain />
    </Fragment>
  );
}
