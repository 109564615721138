import React from "react";

import support from "../../images/Boundless/Rectangle 8 (2).png";
import incubate from "../../images/Boundless/Rectangle 18 (2).png";
import { useNavigate } from "react-router-dom";

const BusinessSupport = () => {
  const navigate = useNavigate();
  return (
    <div className="lg:w-[80%] py-20 w-[90%] mx-auto flex flex-col gap-4">
      <h3 className="text-4xl font-bold text-center capitalize">
        Take your business online and Find talents to support
      </h3>

      <div className="business__products">
        {/* Support Pro */}
        <div className="support__pro">
          <div className="support__image">
            <img src={support} alt="support avatar" />
          </div>
          <div className="support__description">
            <h5>SupportPro</h5>
            <p>
              SupportPro helps you build your marketing and sales Strategies,
              helps with online solutions such as websites and media pages. You
              as well get to hire the best talents and Professionals to support.
            </p>
            <button onClick={() => navigate("/supportpro")}>
              Try SupportPro
            </button>
          </div>
        </div>

        {/* Incubate */}
        <div className="incubate">
          <div className="incubate__description">
            <h5>Incubate</h5>
            <p>
              Get your teams trained to get tech, products and Business
              Development inclined practical knowledge, for exponential growth
              and customer success, by exposure to Industry experts. You can
              also join in as an intern
            </p>
            <button onClick={() => navigate("/incubate")}>Try Incubate</button>
          </div>
          <div className="incubate__image">
            <img src={incubate} alt="Incubate avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSupport;
