import { Box } from "@chakra-ui/react";
import React, { Fragment } from "react";
import IncubateHeader from "./IncubateHeader";
import Hero from "./Hero";
import IncubatePartners from "./IncubatePartners";

export default function IncubateDashboard() {
  return (
    <Fragment>
      <IncubateHeader />
      <Hero />
      <IncubatePartners />
    </Fragment>
  );
}
