import React from "react";
import { Button } from "@chakra-ui/react";

export default function ButtonComponent({ variant, color, bg, title, border }) {
  return (
    <Button
      variant={variant ? variant : "solid"}
      color={color ? color : "white"}
      bg={bg}
      p="0.8rem"
      borderRadius="0.75rem"
      border={border ? border : ""}
    >
      {title}
    </Button>
  );
}
