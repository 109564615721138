import React from "react";
import classes from "./resources.module.css";
import { GoLocation } from "react-icons/go";
import { MdOutlineCall } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";

export default function DontWorry() {
  return (
    <section className={classes.section}>
      <h1>Don’t worry... You can tell us about It</h1>
      <div className={classes.container}>
        <div className={classes.item}>
          <span>
            <GoLocation />
          </span>
          <p>Suite 8, Block LO, Industrial Park, Akure, Nigeria </p>
        </div>

        <div className={classes.item}>
          <span>
            <MdOutlineCall />
          </span>
          <p>
            +2347057799457 <br />
            +2349066259837
          </p>
        </div>

        <div className={classes.item}>
          <span>
            <AiOutlineMail />
          </span>
          <p>info@salesplat.com</p>
        </div>
      </div>
    </section>
  );
}
