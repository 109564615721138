import React from "react";

// Css Import
import "../../styles/Hero.css";
// Images and component imports
import HeroImage from "../../images/hero__image.svg";
import HeroImageSmall from "../../images/heroImageSmall.png";
import ManageYourBusiness from "../Groundforce/ManageYourBusiness";

const Hero = () => {
  return (
    <ManageYourBusiness
      title="Sales, Distribution and Support Solutions for Your Business Growth"
      text="Get unique software tools, platforms, top talents and field agents
    to give your products maximum sales."
      btntext="get started"
      image1={HeroImage}
      image2={HeroImageSmall}
      style={{ backgroundColor: "#DDEFFF" }}
      link="login"
    />
  );
};

export default Hero;
