import React from "react";
import classes from "./resources.module.css";

export default function Message() {
  return (
    <div className={classes.message}>
      <h1>Contact</h1>
      <p>
        We don’t bite.... you know, Let us know what’s on your mind and expect
        our reply soonest.
      </p>
      <form>
        <div className={classes.field}>
          <label>name</label>
          <input type="text" />
        </div>
        <div className={classes.field}>
          <label>email</label>
          <input type="email" />
        </div>
        <div className={classes.field}>
          <label>Tell us how we can help you</label>
          <textarea rows="4" />
        </div>
        <button>submit</button>
      </form>
    </div>
  );
}
