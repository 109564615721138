import React, { useState } from "react";
import classes from "../Groundforce/groundforce.module.css";
import { MdOutlineAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

export default function FAQs() {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  return (
    <section className={classes.faqs}>
      <h1>Frequently Asked Questions/Help</h1>
      <div className={classes.faqContainer}>
        <div onClick={() => setFaq1((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>How do I do this</h2>
            <span>{faq1 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq1 && (
            <p>
              Choose a plan and get onboard in seconds. Enjoy all of GroundForce
              for your business, brand or organisation and yield maximum results
            </p>
          )}
        </div>
        <div onClick={() => setFaq2((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>How do I do this</h2>
            <span>{faq2 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq2 && (
            <p>
              Choose a plan and get onboard in seconds. Enjoy all of GroundForce
              for your business, brand or organisation and yield maximum results
            </p>
          )}
        </div>
        <div onClick={() => setFaq3((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>How do I do this</h2>
            <span>{faq3 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq3 && (
            <p>
              Choose a plan and get onboard in seconds. Enjoy all of GroundForce
              for your business, brand or organisation and yield maximum results
            </p>
          )}
        </div>
        <div onClick={() => setFaq4((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>How do I do this</h2>
            <span>{faq4 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq4 && (
            <p>
              Choose a plan and get onboard in seconds. Enjoy all of GroundForce
              for your business, brand or organisation and yield maximum results
            </p>
          )}
        </div>
      </div>
    </section>
  );
}
