import React from "react";
import laptop from "../../images/Records/laptop.svg";
import classes from "./reccords.module.css";

const SkipTheSpreadsheets = () => {
  return (
    <section className={classes.skip}>
      <h2>Skip the spreadsheets, Manage Everything in one place</h2>
      <p>
        Track expenses, Manage cashflow and Inventory, run reports and more...
      </p>
      <button> use Reccords</button>
      <div className={classes.skipimage}>
        <img alt="reccordsdescription" src={laptop} />
      </div>
    </section>
  );
};

export default SkipTheSpreadsheets;
