import React from 'react'

import '../../styles/Tested.css'

import WhatCanDo from './WhatCanDo'
import GotBack from './GotBack'
import TrustedBrands from './TrustedBrands'


const TestedAndTrusted = () => {
    return (
        <div className="tested">
          
                <TrustedBrands />
                <WhatCanDo />
                <GotBack />
       
        </div>
    )
}

export default TestedAndTrusted
