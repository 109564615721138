import React from "react";
import TotalSales from "./TotalSales";
import TotalCustomers from "./TotalCustomers";
import DetailChart from "./DetailChart";
import Detail from "./Detail";
import RecentActivities from "../RecentActivities";

export default function ProductDetailCenter() {
  return (
    <div className="w-full px-4 lg:px-20 flex flex-col-reverse lg:flex-row items-center gap-4">
      <div className="w-full lg:w-[70%] flex flex-col gap-6 ">
        <div className="w-full flex flex-col lg:flex-row gap-4 items-center ">
          <TotalSales />
          <TotalCustomers />
        </div>
        <DetailChart />
        <RecentActivities />
      </div>
      <div className="w-full lg:flex-1">
        <Detail />
      </div>
    </div>
  );
}
