import React, { useEffect, useState } from "react";

import "../../styles/Auth/Signup.css";

import PhoneInput from "react-phone-input-2";
import axios from "axios";
import Input from "../../components/UI/Input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "../../components/UI/Select";
const url = "https://countriesnow.space/api/v0.1/countries";

const Personal = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    country: "",
    state: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
  });

  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  const getCountries = async () => {
    const response = await axios.get(url);
    setCountries(response.data.data?.map((el) => el.country));
  };

  useEffect(() => {
    getCountries();
  }, []);

  async function signupHandler(e) {
    e.preventDefault();
    try {
      toast.success("Sign up successful");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  console.log(countries);

  return (
    <div className="w-full flex items-center justify-center">
      <form
        onSubmit={signupHandler}
        className="w-full flex flex-col gap-4"
        action=""
      >
        {/* Form group */}
        <div className="auth-style">
          <Input
            label="First name"
            placeholder="Enter First Name"
            value={formData.firstName}
            onChange={(e) => {
              setFormData({
                ...formData,
                firstName: e.target.value,
              });
            }}
          />

          {/* Last Name */}
          <Input
            label="Last name"
            placeholder="Enter Last Name"
            value={formData.lastName}
            onChange={(e) => {
              setFormData({ ...formData, lastName: e.target.value });
            }}
          />
        </div>

        {/* Form group */}
        <div className="auth-style">
          <Input
            label="Email"
            type="email"
            name="email address"
            value={formData.email}
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />

          <div className="flex items-start gap-2 flex-col w-full">
            <label
              htmlFor="phone number"
              className="text-base font-medium text-[#333] capitalize"
            >
              Phone Number
            </label>{" "}
            <PhoneInput
              inputProps={{
                name: "phone",
              }}
              type="number"
              name="phone number"
              value={formData.phoneNumber}
              onChange={(e) => {
                setFormData({ ...formData, phoneNumber: e });
              }}
            />
          </div>
        </div>

        <div className="auth-style">
          <Select
            label="Gender"
            value={formData.gender}
            options={["Male", "Female"]}
            onChange={(e) => {
              setFormData({ ...formData, gender: e.target.value });
            }}
          />

          <Select
            label="Country"
            name="country"
            options={countries || []}
            value={formData.country}
            className="p-3 outline-none border w-full border-[#ccc] rounded-lg text-black"
            onChange={(e) => {
              setFormData({ ...formData, country: e.target.value });
            }}
          />
        </div>

        {/* Form group */}
        <div className="auth-style">
          {/* First Name */}
          <Input
            label="State/Province"
            name="state"
            type="text"
            placeholder="Enter State/Province"
            value={formData.state}
            onChange={(e) => {
              setFormData({ ...formData, state: e.target.value });
            }}
          />

          <Input
            label="Zip Code"
            type="text"
            placeholder="Enter Zip code"
            value={formData.zipCode}
            onChange={(e) => {
              setFormData({ ...formData, zipCode: e.target.value });
            }}
          />
        </div>

        {/* Form group */}
        <div className="auth-style">
          <Input
            label=" Password"
            name="password"
            type="password"
            value={formData.password}
            placeholder="Enter Password"
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />

          <Input
            label="Confirm Password"
            name="confirm password"
            type="password"
            value={formData.confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => {
              setFormData({ ...formData, confirmPassword: e.target.value });
            }}
          />
        </div>

        <div className="submit">
          <button className="w-full bg-[#0d5ba1] text-base text-white font-bold p-3 rounded-lg hover:opacity-80 hover:shadow-lg transition-all duration-300">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default Personal;
