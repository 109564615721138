import React from "react";

import classes from "../styles/footer.module.css";

import { BsFacebook } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";

import Logo from "../images/Footer/logo.svg";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex flex-col w-full p-12 md:p-4 space-y-12 bg-[#117cc3]">
      <div className="flex flex-col p-6 space-y-4 mx-auto my-2">
        <h3 className="w-[80%] font-[Raleway] text-center mx-auto my-2 font-semibold text-white text-2xl md:text-4xl capitalize">
          Related Links
        </h3>
        <p className="w-full font-[Raleway] text-lg text-center text-white font-normal">
          Taking your Business Online, Basic Steps to take for better
          experiences <br /> How to serve an Informal and offline market,
          especially in Africa <br /> How to Sell to anyone who want to sell to
        </p>
        <hr />
      </div>
      <div className="flex flex-col w-full md:flex-wrap space-y-6 items-center justify-between md:flex-row sm:space-x-4">
        <div className="w-full h-full md:w-[20%] md:h-[500px] ">
          {/* Logo */}
          <div className="logo">
            <img src={Logo} alt="Logo svg" />
          </div>
        </div>
        {/* Company */}
        <div className="item">
          <h5 className="h5">Company</h5>
          <p className="w-full text-white font-normal text-start text-lg">
            Our Story
          </p>
          <p className="p">Our Blog</p>
          <p className="p">FAQs</p>
          <Link to="/about">
            <p className="p">About Us</p>
          </Link>
          <p className="p">Privacy Policy</p>
          <p className="p">Terms & Conditions</p>
          <p className="p">Careers</p>
          <p className="p">Help and Support</p>
        </div>
        {/* Get in touch */}
        <div className="item">
          <h5 className="h5">Get in Touch</h5>
          <p className="p">+234 806 491 7302</p>
          <p className="p">Support@mySalesplat.com</p>
        </div>
        {/* Newsletter */}
        <div className="item">
          <h5 className="h5">Newsletter</h5>
          <p className="p">Get updated with the latest news and information</p>
          <form className="flex flex-col space-y-4">
            <input
              className="p-2 bg-transparent text-lg outline-none border-2"
              type="email"
              name="email"
              placeholder="Email address"
            />
            <button className="p-2 text-[#0d5ba1] w-[40%] border-none bg-white border-r-2">
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-6 w-[60%]  mx-auto mt-8">
        <a
          className="a"
          href="https://www.facebook.com/Salesplat?mibextid=ZbWKwL"
        >
          <BsFacebook />
        </a>
        <a
          className="a"
          href="https://instagram.com/salesplat?igshid=YmMyMTA2M2Y="
        >
          <AiOutlineInstagram />
        </a>
        <a
          className="a"
          href="https://twitter.com/SalesPlat?t=ueS37yE6l9gbgTPgk-yNLg&s=09"
        >
          <AiFillTwitterCircle />
        </a>
        <a className="a" href="https://www.linkedin.com/company/salesforce-x/">
          <AiFillLinkedin />
        </a>
      </div>
    </div>
  );
};

export default Footer;
