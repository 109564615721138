import React from "react";
import Footer from "../../components/Footer";
import Incubate from "../../components/Incubate/Incubate";

export default function IncubateDesktop() {
  return (
    <>
      <Incubate />
      <Footer />
    </>
  );
}
