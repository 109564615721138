import React from "react";
import classes from "./supportpro.module.css";
import TImage from "../../images/SupportPro/image 9.png";
import ZImage from "../../images/SupportPro/image 9 (1).png";
const Mentoring = () => {
  return (
    <section className="w-[90%] py-10 flex  items-center justify-center flex-col gap-4 lg:w-[80%] mx-auto">
      <div className="w-full flex items-center justify-center lg:justify-between flex-wrap">
        <div className="w-[90%] mx-auto lg:w-[50%] flex flex-col items-center gap-4">
          <h2 className="text-base lg:text-xl text-center font-bold  text-black">
            Mentoring and consultations
          </h2>
          <p className="text-base font-normal w-[80%] mx-auto text-center lg:text-start">
            Get directions from those that have gone ahead of you. Business
            moguls, Serial entrepreneurs , experts are here to help you ar your
            organisation.
          </p>
          <button className="text-base capitalize font-bold bg-[#0d5ba1] rounded-lg p-3 text-white hover:opacity-80 hover:shadow-lg transition-all duration-300">
            use Supportpro
          </button>
        </div>
        <div className={classes.image}>
          <img alt="description" src={TImage} className={classes.largeScreen} />
          <img alt="description" src={ZImage} className={classes.smallScreen} />
        </div>
      </div>
    </section>
  );
};

export default Mentoring;
