import React, { useState } from "react";

import FirstStep from "../../components/Business/FirstStep";
import SecondStep from "../../components/Business/SecondStep";

const Business = () => {
  const [steps, setSteps] = useState(0);

  const [formData, setFormData] = useState({
    businessName: "",
    category: "",
    businessEmail: "",
    businessPhoneNumber: "",
    businessCountry: "",
    businessState: "",
    address: "",
    businessNumber: "",
    businessZipCode: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    country: "",
    state: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
  });

  console.log(formData);

  const handleStep = () => {
    setSteps(steps + 1);
  };

  const stepper = [
    {
      name: "First Step",
      component: <FirstStep formData={formData} setFormData={setFormData} />,
    },
    {
      name: "Second Step",
      component: <SecondStep formData={formData} setFormData={setFormData} />,
    },
  ];
  return (
    <div>
      <div className="">{stepper[steps].component}</div>
      {steps === 0 ? (
        <div className="next">
          <button
            className="w-full bg-[#0d5ba1] text-base text-white font-bold p-3 rounded-lg hover:opacity-80 hover:shadow-lg transition-all duration-300"
            s
            onClick={handleStep}
          >
            Next
          </button>
        </div>
      ) : (
        ""
      )}
      {steps === 1 && (
        <div className="Sign Up">
          <button className="w-full bg-[#0d5ba1] text-base text-white font-bold p-3 rounded-lg hover:opacity-80 hover:shadow-lg transition-all duration-300">
            Sign Up
          </button>
        </div>
      )}
    </div>
  );
};

export default Business;
