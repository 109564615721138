import React from "react";
import { Link } from "react-router-dom";
import classes from "../../Distribute/distribute.module.css";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function ReusableDistribute({
  title,
  image1,
  image2,
  icon1,
  icon2,
  text1,
  text2,
  text3,
  text4,
  show,
  show1,
  setShow,
  setShow1,
}) {
  return (
    <section className={classes.distribute}>
      <h1>{title}</h1>
      <div className={classes.container}>
        <div
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          className={classes.item}
        >
          <div className={classes.image}>
            <div className={classes.black}></div>
            <img alt="distribute" src={image1} />
            <div className={classes.content}>
              <div className={classes.contents}>
                <span>
                  <img alt="icon" src={icon2} />
                </span>
                <h2>{text1}</h2>
                <div className={classes.text}>
                  <p>{text3}</p>
                  <Link to="">use distribute</Link>
                </div>
                {show && (
                  <div className={classes.texts}>
                    <p>{text3}</p>
                    <Link to="">use distribute</Link>
                  </div>
                )}
              </div>
              <small>
                <AiOutlineInfoCircle />
              </small>
            </div>
          </div>
        </div>

        <div
          onMouseEnter={() => setShow1(true)}
          onMouseLeave={() => setShow1(false)}
          className={classes.item}
        >
          <div className={classes.image}>
            <div className={classes.black}></div>
            <img alt="distribute" src={image2} />
            <div className={classes.content}>
              <div className={classes.contents}>
                <span>
                  <img alt="icon" src={icon1} />
                </span>
                <h2>{text2}</h2>
                <div className={classes.text}>
                  <p>{text4}</p>
                  <Link to="">use distribute</Link>
                </div>
                {show1 && (
                  <div className={classes.texts}>
                    <p>{text3}</p>
                    <Link to="">use distribute</Link>
                  </div>
                )}
              </div>
              <small>
                <AiOutlineInfoCircle />
              </small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
