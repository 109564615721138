import React from "react";
import SImage from "../../images/SupportPro/image 7.png";
import BImage from "../../images/SupportPro/image 7 (1).png";

import classes from "./supportpro.module.css";

const Everything = () => {
  return (
    <section className="w-[90%] flex items-center justify-center flex-col gap-4 lg:w-[80%] mx-auto">
      <h1 className="text-base lg:text-2xl text-center font-bold  text-black">
        Everything you need for your business
      </h1>
      <div className="w-full flex items-center justify-center lg:justify-between flex-wrap">
        <div className="w-[90%] mx-auto lg:w-[45%] flex flex-col items-center gap-4">
          <h2 className="text-base lg:text-xl text-center font-bold  text-black">
            Get Trained by Experts
          </h2>
          <p className="text-base font-normal w-[80%] mx-auto text-center lg:text-start">
            Gain new knowledge, insights and skills from experienced
            professionals to help build your organisation or business.
          </p>
          <button className="text-base capitalize font-bold bg-[#0d5ba1] rounded-lg p-3 text-white hover:opacity-80 hover:shadow-lg transition-all duration-300">
            use Supportpro
          </button>
        </div>
        <div className={classes.image}>
          <img alt="description" src={SImage} className={classes.largeScreen} />
          <img alt="description" src={BImage} className={classes.smallScreen} />
        </div>
      </div>
    </section>
  );
};

export default Everything;
