import React from "react";
import classes from "./groundforce.module.css";
import { useNavigate } from "react-router-dom";

export default function ManageYourBusiness({
  title,
  text,
  image1,
  image2,
  btntext,
  style,
  link,
}) {
  const navigate = useNavigate();
  return (
    <section className={classes.manage} style={style}>
      <div className="w-full lg:w-[90%] mx-auto flex items-center justify-between flex-wrap">
        <div className="w-[90%] md:w-[45%] mx-auto lg:w-[45%] px-2 py-4 flex flex-col gap-3">
          <h2 className="text-3xl font-bold text-start text-black capitalize lg:w-[70%] w-[95%]">
            {title}
          </h2>
          <p className="text-[20px] lg:w-[80%] text-[#2d2d2d] capitalize">
            {text}
          </p>
          <button
            className="p-3 w-[80%] font-bold bg-[#0d5ba1] text-white hover:opacity-80 capitalize"
            onClick={() => navigate(`/${link}`)}
          >
            {btntext}
          </button>
        </div>
        <div className={classes.image}>
          <img alt="description" src={image1} className={classes.largeScreen} />
          <img alt="description" src={image2} className={classes.smallScreen} />
        </div>
      </div>
    </section>
  );
}
