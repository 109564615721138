import React, { useState, Fragment } from "react";
import classes from "../../styles/nav.module.css";

import { Link, NavLink } from "react-router-dom";

import Logo from "../../images/logo.svg";

import { RiArrowDropDownFill } from "react-icons/ri";
import { AiOutlineMenu } from "react-icons/ai";
import ResourcesDropdown from "./dropdown/resourcesDropdown";
import Dropdown from "./dropdown/Dropdown";

export default function MobileHeader({ style }) {
  const [showProduct, setShowProduct] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <header style={style} className={classes.mobile}>
        <div className={classes.menu}>
          <Link to="/" className={classes.logo}>
            <img alt="logo" src={Logo} />
          </Link>
          <div
            onClick={() => setShow((prev) => !prev)}
            className={classes.open}
          >
            <span>
              <AiOutlineMenu />
            </span>
          </div>
        </div>
        {show && (
          <div className={classes.nav}>
            <div className={classes.container}>
              <ul>
                <li
                  onClick={() => {
                    setShowProduct((prev) => !prev);
                  }}
                >
                  <NavLink>
                    product <RiArrowDropDownFill />
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setShowResources((prev) => !prev);
                  }}
                >
                  <NavLink>
                    resources <RiArrowDropDownFill />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about">company</NavLink>
                </li>
                <li>
                  <NavLink to="/partner">partner</NavLink>
                </li>
                <li>
                  <NavLink to="/pricing">pricing</NavLink>
                </li>
                <li>
                  <NavLink to="/faq">FAQ</NavLink>
                </li>
              </ul>
              <div className={classes.auth}>
                <Link className={classes.login} to="/login">
                  login
                </Link>
                <Link className={classes.start} to="/sign-up">
                  get started
                </Link>
              </div>
            </div>
          </div>
        )}
      </header>
      {showResources && (
        <ResourcesDropdown setShowResources={setShowResources} />
      )}
      {showProduct && <Dropdown setShowProduct={setShowProduct} />}
    </Fragment>
  );
}
