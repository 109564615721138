import React from "react";
import classes from "./incubate.module.css";
import creators from "../../images/Incubate/Rectangle 225.png";
import creatorssmall from "../../images/Incubate/Rectangle 225 (1).png";
const Creators = () => {
  return (
    <div className="w-[90%] lg:w-[80%] mx-auto flex flex-col md:flex-row gap-4 py-6 lg:gap-0 lg:flex-row">
      <div className="w-[90%] p-4 lg:h-[420px] lg:w-[55%] mx-auto items-center justify-center  flex flex-col gap-3">
        <p className="text-[30px] font-bold text-center text-black">
          Incubate for Learners
        </p>
        <h2 className="text-[20px] font-medium text-center text-black">
          Become an Instructor
        </h2>
        <p className="text-center w-[80%] mx-auto">
          Reach new audiences and students with Incubate. We provide the tools
          and skills to teach what you love.
        </p>
        <button className="p-3 rounded-lg hover:opacity-80 hover:shadow-lg text-white font-bold bg-[#0d5ba1]">
          Use Incubate
        </button>
      </div>
      <div className={classes.image}>
        <img
          alt="join description"
          src={creators}
          className={classes.largeScreen}
        />
        <img
          alt="join description"
          src={creatorssmall}
          className={classes.smallScreen}
        />
      </div>
    </div>
  );
};

export default Creators;
