import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";

export default function CustomerTitle() {
  return (
    <div className="w-full p-2 bg-[#EAECF0] flex items-center">
      <div className="w-[30%] p-2">
        <h1 className="text-[0.8rem] font-medium capitalize flex items-center gap-2">
          Customer{" "}
          <span className="stat-arrow">
            <AiOutlineArrowDown />
          </span>
        </h1>
      </div>
      <div className="hidden lg:block w-[30%] p-2">
        <h1 className="text-[0.8rem] font-medium capitalize">Product</h1>
      </div>
      <div className=" hidden lg:block w-[30%] p-2">
        <h1 className="text-[0.8rem] font-medium capitalize">Audience Type</h1>
      </div>
      <div className="hidden lg:block flex-1 p-2"></div>
    </div>
  );
}
