import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import React from "react";

// Styles
import "./App.css";
import "./styles/Products/Distribute.css";

// Pages
import Home from "./pages/Home";
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import About from "./pages/About";
import Contact from "./components/ContactUs/Contact";
import FAQS from "./pages/FAQS";
import Groundforce from "./pages/Products/Groundforce";
import IncubateDesktop from "./pages/Products/IncubateDesktop";
import Distributes from "./pages/Products/Distribute";
import Reccord from "./pages/Products/Reccords";
import Affils from "./pages/Products/Affils";
import Resource from "./pages/Resource";
import SupportPro from "./components/SupportPro/SupportPro";
import GroundforceDashboardPage from "./pages/GroundforceDashboardPage";
import GroundforceProductPage from "./pages/GroundforceProductPage";
import GroundforceProductDetailPage from "./pages/GroundforceProductDetailPage";
import IncubateDashboardPage from "./pages/Products/IncubateDashboardPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/faqs" element={<FAQS />} />
        <Route path="/distribute" element={<Distributes />} />
        <Route path="/groundforce" element={<Groundforce />} />
        <Route path="/incubate" element={<IncubateDesktop />} />
        <Route path="/reccords" element={<Reccord />} />
        <Route path="/affil" element={<Affils />} />
        <Route path="/resources" element={<Resource />} />
        <Route path="/supportpro" element={<SupportPro />} />
        <Route
          path="/groundforce-dashboard"
          element={<GroundforceDashboardPage />}
        />
        <Route
          path="/groundforce-products"
          element={<GroundforceProductPage />}
        />
        <Route
          path="/groundforce-products/overview/:id"
          element={<GroundforceProductDetailPage />}
        />
        <Route path="/incubate-dashboard" element={<IncubateDashboardPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
