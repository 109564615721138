import React from "react";
import "../../styles/About.css";

const MainAbout = () => {
  return (
    <div className="mainAbout__section">
      {false && (
        <div className="mainAbout__head">
          <p>
            <span>SALESPLAT</span> is a Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit.
          </p>
        </div>
      )}
    </div>
  );
};

export default MainAbout;
