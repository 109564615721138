import React, { Fragment } from "react";
import "../../styles/About.css";
import classes from "./about.module.css";

import image from "../../images/About/about.png";
import image1 from "../../images/About/Rectangle 62 (1).svg";

const Hero = () => {
  return (
    <Fragment>
      <div className={classes.image}>
        <img alt="about" src={image} />

        <div className={classes.content}>
          <h1>Sales </h1>
          <h1>|</h1>
          <h1>Distribution</h1>
          <h1>|</h1>
          <h1>Support</h1>
        </div>
      </div>
      <div className={classes.images}>
        <img alt="about" src={image1} />
        <div className={classes.content}>
          <h1>Sales </h1>

          <h1>Distribution</h1>

          <h1>Support</h1>
        </div>
      </div>
    </Fragment>
  );
};

export default Hero;
