import React from "react";
import ProductItem from "./ProductItem";

export default function ProductList() {
  const data = [1, 2, 3, 4, 5, 6];
  return (
    <div className="w-full px-4 gap-4 lg:px-20 flex flex-col lg:flex-row items-center justify-between flex-wrap">
      {data.map((item, i) => (
        <ProductItem item={item} key={i} />
      ))}
    </div>
  );
}
