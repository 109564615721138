import React, { useState } from "react";
import ReusableDistribute from "../UI/reusableDistribute/ReusableDistribute";

import image1 from "../../images/distribute/Rectangle 65.png";
import image2 from "../../images/distribute/Rectangle 66.png";

import icon1 from "../../images/distribute/Frame 124 (2).png";
import icon2 from "../../images/distribute/Frame 124 (3).png";

export default function GetGood() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  return (
    <ReusableDistribute
      title="Get your Goods to your Customers"
      text1="Manufacturing"
      text2="Retail"
      text3="Showcase your products, Connect to your consumers through distributors, logistics, storage and retailers"
      text4="Make and track orders directly from manufacturers and distributors, Digitalize your processes and more..."
      image1={image1}
      image2={image2}
      icon1={icon1}
      icon2={icon2}
      show={show}
      setShow={setShow}
      show1={show1}
      setShow1={setShow1}
    />
  );
}
