import React, { Fragment } from "react";
import ManageYourBusiness from "../Groundforce/ManageYourBusiness";

import image1 from "../../images/affil/Rectangle 5 (2).svg";
import image2 from "../../images/affil/Rectangle 5 (3).svg";
import SellProduct from "./SellProduct";
import MakeMore from "./MakeMore";
import Affiliate from "./Affiliate";
import FAQs from "../UI/FAQs";
import Expand from "../UI/Expand";
import Footer from "../Footer";
import Navbar from "../Navbar";

export default function Affil() {
  return (
    <Fragment>
      <Navbar style={{ backgroundColor: "#FFE0E0" }} />
      <ManageYourBusiness
        text="Tap into affiliate networks and let others sell your product for you"
        title="Set up affiiiate sales for your products and services."
        image1={image1}
        image2={image2}
        btntext="Use affils"
        link="affil-dashboard"
        style={{ backgroundColor: "#FFE0E0" }}
      />
      <SellProduct />
      <MakeMore />
      <Affiliate />
      <FAQs />
      <Expand text="Sell Your Products" title="affils" />
      <Footer />
    </Fragment>
  );
}
