import React from "react";

import "../../styles/Boundless.css";

import distribute from "../../images/Boundless/Rectangle 8.png";
import offline from "../../images/Boundless/Rectangle 18.png";

import { Link, useNavigate } from "react-router-dom";

const Digitalize = () => {
  const navigate = useNavigate();
  return (
    <div className="lg:w-[80%] w-[90%] py-20 mx-auto flex flex-col gap-4">
      <h3 className="text-4xl font-bold text-center capitalize">
        Digitalize your Distribution and Book Keeping
      </h3>

      <div className="digitalize__products">
        {/* Distribute */}
        <div className="distribute">
          <div className="distribute__image">
            <img src={distribute} alt="Distribute svg" />
          </div>
          <div className="distribute__description">
            <h5>Distribute</h5>
            <p>
              Bridging the gap between manufacturers, distributors and
              retailers, we provide retailers access to a remote ordering
              platform, Aggregating demand and connecting relevant logistics and
              warehouses{" "}
            </p>
            <Link to="/distribute">
              <button>Try Distribute</button>
            </Link>
          </div>
        </div>

        {/* Offline */}
        <div className="offline">
          <div className="offline__description">
            <h5>Reccords</h5>
            <p>
              A simplified book-keeping and Inventory Management tool built for
              SMEs, traders and Informal markets to track their finances, sales,
              products and other things necessary, offline and online.
            </p>
            <button onClick={() => navigate("/reccords")}>Try Reccords</button>
          </div>
          <div className="offline__image">
            <img src={offline} alt="offline avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Digitalize;
