import React, { useEffect, useState } from "react";

import PhoneInput from "react-phone-input-2";
import { getCountries } from "../../lib/countries";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Input from "../UI/Input";
import Select from "../UI/Select";

const FirstStep = ({ formData, setFormData }) => {
  // eslint-disable-next-line
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setCountries(getCountries());
  }, []);

  async function signupHandler(e) {
    e.preventDefault();
    try {
      toast.success("Sign up successful");
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="w-full flex items-center justify-center">
      <form
        onSubmit={signupHandler}
        className="w-full flex flex-col gap-4"
        action=""
      >
        <div className="auth-style">
          <Input
            label="Business Name"
            placeholder="Enter Business Name"
            name="business name"
            value={formData.businesName}
            onChange={(e) => {
              setFormData({ ...formData, businesName: e.target.value });
            }}
          />
          <Select
            label="Business Category"
            options={[]}
            value={formData.category}
          />
        </div>

        <div className="auth-style">
          <Input
            type="email"
            label="Business Email"
            placeholder="Enter Business Email"
            name="Business Email"
            value={formData.businessEmail}
            onChange={(e) => {
              setFormData({
                ...formData,
                businessEmail: e.target.value,
              });
            }}
          />

          <div className="flex items-start gap-2 flex-col w-full">
            <label
              htmlFor="phone number"
              className="text-base font-medium text-[#333] capitalize"
            >
              Business Phone Number
            </label>{" "}
            <PhoneInput
              inputProps={{
                name: "phone",
              }}
              name="phone number"
              value={formData.businessPhoneNumber}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  businessPhoneNumber: e,
                });
              }}
            />
          </div>
        </div>

        <div className="auth-style">
          <Select
            label="Business Country"
            options={[]}
            name="country"
            value={formData.businessCountry}
            onChange={(e) => {
              setFormData({ ...formData, businessCountry: e.target.value });
            }}
          />

          <Input
            label="State/Province"
            placeholder="Enter State/Province"
            name="State/Province"
            value={formData.businessState}
            onChange={(e) => {
              setFormData({ ...formData, businessState: e.target.value });
            }}
          />
        </div>

        <div className="auth-style">
          <Input
            label="Address"
            placeholder="Enter Address"
            type="address"
            value={formData.address}
            onChange={(e) => {
              setFormData({ ...formData, address: e.target.value });
            }}
          />

          <Input
            label="Business Registration Number"
            placeholder="Enter Business Registration Number"
            value={formData.businessNumber}
            onChange={(e) => {
              setFormData({ ...formData, businessNumber: e.target.value });
            }}
          />
        </div>

        <div className="w-full justify-between pb-8 flex items-center gap-4">
          <Input
            label="Zip Code"
            placeholder="Enter Zip Code"
            type="Zip Code"
            value={formData.businessZopCode}
            onChange={(e) => {
              setFormData({ ...formData, businessZopCode: e.target.value });
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default FirstStep;
