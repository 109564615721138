import React from "react";
import "../../styles/About.css";

import Member from "../../images/About/member.png";

const team = [
  { id: 1, picture: Member, position: "Position", name: "Name of Person" },
  { id: 1, picture: Member, position: "Position", name: "Name of Person" },
  { id: 1, picture: Member, position: "Position", name: "Name of Person" },
  { id: 1, picture: Member, position: "Position", name: "Name of Person" },
  { id: 1, picture: Member, position: "Position", name: "Name of Person" },
  { id: 1, picture: Member, position: "Position", name: "Name of Person" },
];

const Story = () => {
  return (
    <div className="aboutStory__section">
      <div className="about__story">
        <h3>Our Story</h3>
        <p>
          <p>
            <span>Our Story</span> is that of a brand that was created out of
            the need to fill a gap for businesses. It was created after we have
            solved the same problems of Sales, distribution, and Business growth
            for ourselves.
          </p>
          <p>
            Over the last few years, from zero the teams here on Salesplat have
            been able to raise sales leaders, agents, digital talents, and
            operators which have pushed various brands we are part of, to hit
            over 200,000 users offline and online, over 5,000 SMEs, hundreds of
            clusters and partnerships, of across industries like FinTech,
            Agriculture, Energy, Education, Health and more, yet with extremely
            low Customer Acquisition Costs.
          </p>
          <p>
            We have facilitated distribution with no single asset yet at over
            90% satisfaction level of the distributors and commerce businesses
            serviced.
          </p>
          This result and experience is what we have decided to replicate via
          the Salesplat brand to plug your needs for customized Tech Tools,
          Personnels, Strategies, and support to win the informal and formal
          markets across your chosen sectors, which can then expand your growth
          and improve your business and personal profitability
        </p>
      </div>
      <div className="about__vision">
        <h3>Our Vision</h3>
        <p>
          To build Africa’s largest Sales and Distribution network for physical,
          digital and technology products, online and offline.
        </p>
      </div>
      <div className="about__mission">
        <h3>Our Mission</h3>
        <p>
          To leverage training, partnerships, and technical tools development to
          drive distribution, customer success, retention and exponential growth
          for our clients
        </p>
      </div>
      <div className="about__goals">
        <h3>Our Goals</h3>
        <p>
          1. Build the largest Salesforce of 50,000 personnels for Tech, digital
          and Physical products Sales and Marketing on the Continent
          <p>
            2. Build the most effective networks and ecosystem for
            manufacturers, producers, retailers, logistics players and
            warehouses to aid market penetration of innovations and products
          </p>
          <p>
            3. Become the most reliable supplier of tools and solutions to
            maximize Sales Teams and Distribution operations for Tech and
            Physical products
          </p>
          <p>
            4. Become the Go-to brand for Tech Firms to train their staff, as
            well as hire the best talents for sales, marketing, and digital
            support for both B2B, B2C and B2B2C businesses spanning ages and
            demographics
          </p>
          <p>
            5. Create Top notch and high income jobs, and increase earning power
            of African youths who are our employees, talents or outsourcing
            pools
          </p>
        </p>
      </div>
      {false && (
        <div className="about__team">
          <h3>Our Team</h3>
          <div className="members__cards">
            {team.map((member) => (
              <div className="member__card" key={member.id}>
                <img src={member.picture} alt="Member avatar" />
                <h5>Name of Person</h5>
                <p>Position</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Story;
