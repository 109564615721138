import React from "react";
import Message from "./Message";
import classes from "./resources.module.css";
import Socials from "./Socials";

export default function Contact() {
  return (
    <div className={classes.contact}>
      <Message />
      <Socials />
    </div>
  );
}
