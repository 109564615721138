import React from "react";
import { Fragment } from "react";
import Distribute from "../../components/Distribute/Distribute";

export default function Distributes() {
  return (
    <Fragment>
      <Distribute />
    </Fragment>
  );
}
