import React from "react";
import Footer from "../Footer";
import Expand from "../UI/Expand";
import FAQs from "../UI/FAQs";
import classes from "./groundforce.module.css";
import Join from "./Join";
import ManageYourBusiness from "./ManageYourBusiness";
import MarketExpansion from "./MarketExpansion";
import ReachCustomer from "./ReachCustomer";

import groundforce from "../../images/groundforce/Rectangle 5.png";
import groundforceSmall from "../../images/groundforce/Rectangle 64.png";
import Navbar from "../Navbar";

export default function GroundForce() {
  return (
    <section className={classes.section}>
      <Navbar style={{ backgroundColor: "#FFEDDD" }} />
      <ManageYourBusiness
        title="Manage your business, Optimize distribution, Scale your Profit..."
        text=" With Groundforce, you can manage and track distribution agents ,
      sales, finances and keep the profits rolling in..."
        image1={groundforce}
        image2={groundforceSmall}
        btntext="use groundforce"
        link="groundforce-dashboard"
      />
      <MarketExpansion />
      <ReachCustomer />
      <Join />
      <FAQs />
      <Expand
        link="groundforce-dashboard"
        text="Expand Your Market!"
        title="groundforce"
      />
      <Footer />
    </section>
  );
}
