import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import ButtonComponent from "../UI/Button";
import ImageComponent from "../UI/Image";

import heroImg from "../../images/hero.png";

export default function Hero() {
  return (
    <Flex
      px={{ lg: "2.5rem", md: "1rem", base: "1rem" }}
      w="100%"
      align="center"
      wrap="wrap"
      justify={{ lg: "space-between", md: "space-between", base: "center" }}
    >
      <Flex
        width={{ lg: "50%", md: "50%", base: "90%" }}
        align="start"
        justify="start"
        direction="column"
        gap="1rem"
        p={{ lg: "0rem", base: "1rem", md: "1rem" }}
      >
        <Heading
          w="80%"
          textAlign="start"
          as="h2"
          fontSize={{ lg: "60px", md: "30px", base: "30px" }}
          fontWeight="bold"
        >
          Get your Revenue Growth Related Trainings from the Best
        </Heading>
        <Text
          w="80%"
          textAlign="start"
          color="#667085"
          fontSize={{ lg: "20px", md: "20px", base: "16px" }}
        >
          Access our online platform for cohort-based courses, self-paced
          courses, boot camps, and industry mentorship programs to kickstart
          your learning journey
        </Text>
        <Flex justify="start" align="center" gap="1rem">
          <ButtonComponent
            title="View programs"
            color="#344054"
            variant="outline"
            border="1px solid #ccc"
          />
          <ButtonComponent title="Start Learning" bg="#4884DF" />
        </Flex>
      </Flex>
      <Box width={{ lg: "50%", md: "50%", base: "90%" }}>
        <ImageComponent src={heroImg} alt="incubate" />
      </Box>
    </Flex>
  );
}
