import axios from "axios";

const url = "https://countriesnow.space/api/v0.1/countries";

export const getCountries = async () => {
  await axios.get(url).then((response) => {
    let countries = response.data.data?.map((el) => el.country);
    return countries;
  });
};
