import React, { Fragment } from "react";
import Footer from "../Footer";
import CrazySales from "../HomeComponents/CrazySales";
import Navbar from "../Navbar";
import Contact from "./Contact";
import DontWorry from "./DontWorry";

export default function Resources() {
  return (
    <Fragment>
      <Navbar />
      <DontWorry />
      <Contact />
      <CrazySales />
      <Footer />
    </Fragment>
  );
}
