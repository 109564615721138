import React from "react";
import { TbCrown } from "react-icons/tb";
import { FiAtSign } from "react-icons/fi";
import { BsFlag } from "react-icons/bs";
import { IoIosRadio } from "react-icons/io";
import classes from "./supportpro.module.css";

function ReusableTake({ title, text, icon }) {
  return (
    <div className="lg:w-[45%] my-3 w-[45%] mx-auto flex items-start flex-col gap-2">
      <span className="text-xl text-[#0D5BA1] lg:text-[44px]">{icon}</span>
      <h3 className="text-base  lg:text-xl font-bold text-black text-start">
        {title}
      </h3>
      <p className="text-base  font-normal text-start w-full lg:w-[80%]">
        {text}
      </p>
    </div>
  );
}

const Take = () => {
  return (
    <section className={classes.takecontainer}>
      <h1>Take your Business to new heights With Supportpro</h1>
      <div className="lg:w-[60%] mx-auto w-[90%] flex items-center justify-between flex-wrap">
        <ReusableTake
          title="user/ customer experience"
          text="Get to know your customers, their likes and dislikes, positon your
        business to fill their needs."
          icon={<TbCrown />}
        />

        <ReusableTake
          title="Online Visibility"
          text="Web creation, social media management all at your fingertips."
          icon={<FiAtSign />}
        />

        <ReusableTake
          title="Brand Awareness"
          text="Brandiing solutions, content creation, analytics... Reach new
        prospects."
          icon={<BsFlag />}
        />

        <ReusableTake
          title="Digital Marketing"
          text=" Market to the millions of potential who may not have heard about
        your business name"
          icon={<IoIosRadio />}
        />
      </div>
    </section>
  );
};

export default Take;
