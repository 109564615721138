import React from "react";
import ManageYourBusiness from "../Groundforce/ManageYourBusiness";
import Navbar from "../Navbar";
import FAQs from "./FAQs";
import ManageBusiness from "./ManageBusiness";
import Powerful from "./Powerful";
import SkipTheSpreadsheets from "./SkipTheSpreadsheets";

import recordshero from "../../images/Records/recordshero.svg";
import recordsherosmall from "../../images/Records/recordsherosmall.svg";

const Reccords = () => {
  return (
    <div>
      <Navbar style={{ backgroundColor: "rgba(222, 202, 255, 0.64)" }} />
      <ManageYourBusiness
        title="Manage your cashflow and records, expand your business"
        text="Control, Manage and Analyze our business numbers on Reccords.. "
        btntext="Use Reccords"
        image1={recordshero}
        image2={recordsherosmall}
        style={{ backgroundColor: "rgba(222, 202, 255, 0.64)" }}
      />
      <SkipTheSpreadsheets />
      <Powerful />
      <FAQs />
      <ManageBusiness />
    </div>
  );
};

export default Reccords;
