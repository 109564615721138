import React from "react";

import "../../styles/LearnCraze.css";

import Learn from "../../images/Learn/learner.svg";
import CrazySales from "./CrazySales";

const LearnCraze = () => {
  return (
    <div className='lg:w-[80%] py-20 w-[90%] mx-auto flex flex-col gap-4'>
      
        <h3 className='text-4xl font-bold text-center capitalize'>Learn all you need</h3>

        <div className="learn">
          <div className="learn__description">
            <h5>SalesPlat Webinars</h5>
            <p>
              We understand that for a business, it’s not just about the tools,
              but the knowledge you have as a business owners. We have a series
              of webinars and trainings for new SalesPlat Users.
            </p>
            <button>Register Now</button>
          </div>
          <div className="learn__image">
            <img src={Learn} alt="Learn avatar" />
          </div>
        </div>

        {/* Craze */}
        <CrazySales />
      </div>
   
  );
};

export default LearnCraze;
