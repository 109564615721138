import React, { useState } from "react";
import classes from "./reccords.module.css";
import { MdOutlineAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

export default function FAQs() {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq4, setFaq4] = useState(false);
  return (
    <section className={classes.faqs}>
      <h1>Frequently Asked Questions/Help</h1>
      <div className={classes.faqContainer}>
        <div onClick={() => setFaq1((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>Can I connect my account with Groundforce or Distribute?</h2>
            <span>{faq1 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq1 && (
            <p>
              Yes, you can Link your account to groundforce or distrbute to use
              all their services
            </p>
          )}
        </div>
        <div onClick={() => setFaq2((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>Can I access Reccords with my phone or tablet?</h2>
            <span>{faq2 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq2 && (
            <p>
              Yes you can. You can get full access to Reccords with your phone,
              tablet or computer.
            </p>
          )}
        </div>
        <div onClick={() => setFaq4((prev) => !prev)} className={classes.faq}>
          <div className={classes.faqTitle}>
            <h2>Are online payment options available?</h2>
            <span>{faq4 ? <AiOutlineMinus /> : <MdOutlineAdd />}</span>
          </div>
          {faq4 && (
            <p>
              Yes, online payment options are available for you and your
              customers.
            </p>
          )}
        </div>
      </div>
      <div className={classes.faqbtn}>
        <button> See more Faqs</button>
      </div>
    </section>
  );
}
