import React, { useState } from "react";

import "../../styles/Auth/Login.css";
import Logo from "../../images/logo.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import SideLogin from "./SideLogin";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
      setHidePassword(true);
    }
  };

  const handleHidePassword = () => {
    if (hidePassword) {
      setHidePassword(false);
      setShowPassword(true);
    }
  };

  function loginHandler(e) {
    e.preventDefault();

    if (!email && !password) {
      toast.error("Email and password fields cannot be empty");
      return;
    }
    if (!email || !password) {
      toast.error("Email or password fields cannot be empty");
      return;
    }
    const data = {
      email,
      password,
    };
    console.log(data);
    navigate("/");
    setEmail("");
    setPassword("");
  }

  return (
    <div className="login__area">
      <div className="login__form">
        <div className="login__head">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo avatar" />
            </Link>
          </div>
          <h3>Log In</h3>
          <p>
            Don’t have an account? Sign Up <Link to="/sign-up">Here</Link>{" "}
          </p>
        </div>
        <form onSubmit={loginHandler}>
          <div className="form__group">
            <div className="layer email__layer">
              <label htmlFor="Email">Email</label> <br />
              <span>
                <i className="far fa-envelope"></i>
              </span>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                name="email"
              />
            </div>
          </div>
          <div className="form__group">
            <div className="layer password__layer">
              <label htmlFor="password">Password</label> <br />
              <span className="lock__icon">
                <i className="fas fa-lock"></i>
              </span>
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                name="password"
              />
              {showPassword && (
                <span className="show__password" onClick={handleShowPassword}>
                  <i className="fas fa-eye"></i>
                </span>
              )}
              {hidePassword && (
                <span className="hide__password" onClick={handleHidePassword}>
                  <i className="far fa-eye-slash"></i>
                </span>
              )}
            </div>
          </div>

          <div className="forgot__password">
            <h5>Forgot Password?</h5>
          </div>

          <div className="action">
            <button>Login</button>
          </div>
        </form>
      </div>
      <SideLogin />
      <ToastContainer position="top-right" autoClose={2000} />
    </div>
  );
};

export default Login;
