import React from "react";

import "../../styles/Boundless.css";

import CostMarketing from "./CostMarketing";
import BusinessSupport from "./BusinessSupport";
import Digitalize from "./Digitalize";

const Boundless = () => {
  return (
    <div className="container boundless">
      <div className="boundless__head py-20">
        <h3>Grow, Be Boundless!</h3>
        <p>
          We Decentralize, Digitize and Optimize Trade, Bridging Online and
          Offline Customer Access
        </p>
      </div>
      {/* Cost Marketing */}
      <CostMarketing />
      {/* Business Support */}
      <BusinessSupport />
      {/* Digitalize */}
      <Digitalize />
    </div>
  );
};

export default Boundless;
