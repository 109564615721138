import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineArrowDropDown } from "react-icons/md";

// Logo images
import Logo from "../images/logo.svg";
import { RiArrowDropDownFill } from "react-icons/ri";

// Css
// import "./Navbar.css";
import classes from "../styles/nav.module.css";

import Dropdown from "./UI/dropdown/Dropdown";
import ResourcesDropdown from "./UI/dropdown/resourcesDropdown";
import MobileHeader from "./UI/mobileHeader";

const Navbar = ({ style }) => {
  const [showProduct, setShowProduct] = useState(false);
  const [showResources, setShowResources] = useState(false);
  return (
    <Fragment>
      <header style={style} className={classes.header}>
        <Link to="/" className={classes.logo}>
          <img alt="logo" src={Logo} />
        </Link>
        <div className={classes.link}>
          <ul>
            <li
              onClick={() => {
                setShowProduct((prev) => !prev);
              }}
            >
              <NavLink>
                product <RiArrowDropDownFill />
              </NavLink>
            </li>
            <li
              onClick={() => {
                setShowResources((prev) => !prev);
              }}
            >
              <NavLink>
                resources <RiArrowDropDownFill />
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">company</NavLink>
            </li>
            <li>
              <NavLink to="/partner">partner</NavLink>
            </li>
            {false && (
              <li>
                <NavLink to="/pricing">pricing</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/faqs">FAQ</NavLink>
            </li>
          </ul>
        </div>
        <div className={classes.auth}>
          <Link className={classes.login} to="/login">
            login
          </Link>
          <Link className={classes.start} to="/sign-up">
            get started
          </Link>
        </div>
      </header>
      <MobileHeader style={style} />
      {showResources && (
        <ResourcesDropdown setShowResources={setShowResources} />
      )}
      {showProduct && <Dropdown setShowProduct={setShowProduct} />}
    </Fragment>
  );
};

export default Navbar;
