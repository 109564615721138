import React from "react";

import "../../styles/Tested.css";

// Brand images
import One from "../../images/Tested/Rectangle 19.png";
import Two from "../../images/Tested/IMG-20210927-WA0027.png";
import Three from "../../images/Tested/image 1.png";
import Four from "../../images/Tested/Rectangle 21.png";
import Five from "../../images/Tested/Rectangle 24.png";
import Six from "../../images/Tested/Rectangle 22.png";

const TrustedBrands = () => {
  return (
    <div className="flex flex-col py-20 gap-4 items-center">
      <h3 className="text-xl font-bold text-black text-center">
        Tested and Trusted by
      </h3>

      <div className="flex items-center justify-between w-[90%] lg:w-[80%] first-letter:mx-auto">
        <img className="object-contain" src={One} alt="One avatar" />
        <img className="object-contain" src={Two} alt="One avatar" />
        <img className="object-contain" src={Three} alt="One avatar" />
        <img className="object-contain" src={Four} alt="One avatar" />
        <img className="object-contain" src={Five} alt="One avatar" />
        <img className="object-contain" src={Six} alt="One avatar" />
      </div>
    </div>
  );
};

export default TrustedBrands;
