import React from "react";

import "../../styles/Tested.css";

// Images
import small from "../../images/gotBack/Rectangle 37.svg";
import manufacturers from "../../images/gotBack/Rectangle 37 (1).svg";
import startup from "../../images/gotBack/Rectangle 37 (2).svg";
import distributor from "../../images/gotBack/Rectangle 37 (3).svg";
import mom from "../../images/homepage/Rectangle 37 (1).png";
import interns from "../../images/homepage/Rectangle 37 (2).png";
import warehouse from "../../images/homepage/Rectangle 37 (3).png";
import digital from "../../images/homepage/Rectangle 37.png";

function ReusableWhatCanDo({ image, title }) {
  return (
    <div className="w-[90%] md:w-[45%] mx-auto lg:w-[22%] my-3 relative">
      <div className="relative w-full">
        <img
          style={{
            borderTopLeftRadius: "2rem",
            borderBottomRightRadius: "2rem",
          }}
          className="w-full  object-cover h-[300px]"
          src={image}
          alt="small avatar"
        />
        <div
          style={{
            borderTopLeftRadius: "2rem",
            borderBottomRightRadius: "2rem",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          className="absolute top-0 h-[300px]  w-full"
        ></div>
      </div>
      <div className="p-4  z-40 absolute bottom-[10%]">
        <p className="text-white text-base">{title}</p>
      </div>
    </div>
  );
}

const WhatCanDo = () => {
  return (
    <div className="w-[90%] lg:w-[80%] mx-auto flex flex-col gap-4">
      <h3 className="text-xl font-bold text-black text-center">
        What you can do with SalesPlat
      </h3>

      <div className="flex w-full items-center justify-between flex-wrap">
        {/* Small */}
        <ReusableWhatCanDo
          image={small}
          title="Small businesses and Personal Brands"
        />

        {/* Manufacturer */}
        <ReusableWhatCanDo
          image={manufacturers}
          title="Manufacturers and Digital Creators"
        />

        {/* Startup */}
        <ReusableWhatCanDo
          image={startup}
          title="Tech StartUps and Marketing Units "
        />

        {/* distributor */}
        <ReusableWhatCanDo
          image={distributor}
          title="Distributors and Small Business Owners "
        />

        <ReusableWhatCanDo
          image={warehouse}
          title="Small businesses and Personal Brands "
        />

        {/* CREATOR */}
        <ReusableWhatCanDo image={digital} title="Digital Creators  " />

        {/* MOM AND POPS RETAIL */}
        <ReusableWhatCanDo image={mom} title="Mom & Pops, Retail and MSMEs" />

        {/* STUDENT INTERN */}
        <ReusableWhatCanDo
          image={interns}
          title="Students, Interns and Professionals"
        />
      </div>
    </div>
  );
};

export default WhatCanDo;
