import React from "react";
import classes from "./groundforce.module.css";
import { useNavigate } from "react-router-dom";

export default function ReachCustomer() {
  const navigate = useNavigate();
  return (
    <section className={classes.customer}>
      <div className={classes.customer1}>
        <h2>Reach your customers wherever they are</h2>
        <p>
          State, Town, City, Street... No matter where it is in Africa,
          Groundforce has got you covered
        </p>
        <button onClick={() => navigate("/groundforce-dashboard")}>
          use groundforce
        </button>
      </div>
      <div className={classes.customer2}>
        <div className={classes.custom}>
          <h2>Increase Market share</h2>
          <p className={classes.p}>
            Increase your sales, Make your potential customers aware of your
            business/brand, reach new customers and see the result for yourself
          </p>
          <button onClick={() => navigate("/groundforce-dashboard")}>
            use groundforce
          </button>
        </div>
      </div>
      <div className={classes.customer1}>
        <h2>Overtake your competitors</h2>
        <p>
          Leave no room or customers for your competitors. With your army of
          agents at your back, No one can stop you!
        </p>
        <button onClick={() => navigate("/groundforce-dashboard")}>
          use groundforce
        </button>
      </div>
    </section>
  );
}
