import React from "react";
import { BsArrowRight } from "react-icons/bs";
import ActivityList from "./ActivityList";

export default function RecentActivities() {
  return (
    <div className="w-full flex flex-col gap-4 lg:h-[120rem] p-2 lg:p-4">
      <div className="w-full flex items-center p-2 justify-between">
        <h1 className="text-[#101828] font-medium text-[1.2rem] capitalize">
          Recent activities
        </h1>
        <div className="flex items-center gap-2">
          <span className="text-base font-normal text-[#667085]">View All</span>
          <span className="stat-arrow">
            <BsArrowRight />
          </span>
        </div>
      </div>
      <ActivityList />
    </div>
  );
}
