import React from "react";

import '../App.css'

// Home Components
import Hero from "../components/HomeComponents/Hero";
import Boundless from "../components/HomeComponents/Boundless";
import TestedAndTrusted from "../components/HomeComponents/TestedAndTrusted";
import LearnCraze from "../components/HomeComponents/LearnCraze";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Boundless />
      <TestedAndTrusted />
      <LearnCraze />
      <Footer />
    </div>
  );
};

export default Home;
