import React from "react";
import DashboardChart from "./DashboardChart";
import MyCustomers from "./MyCustomers";
import RecentActivities from "./RecentActivities";

export default function DashboardContainer() {
  return (
    <div className="w-full py-6 px-4 lg:px-20 flex flex-col lg:flex-row items-center">
      <div className="w-full lg:w-[70%] flex flex-col gap-6">
        <DashboardChart />
        <MyCustomers />
      </div>
      <div className="w-full lg:flex-1">
        <RecentActivities />
      </div>
    </div>
  );
}
