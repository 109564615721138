import React, { useState } from "react";
import classes from "./affil.module.css";

import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import image from "../../images/affil/Rectangle 210.svg";

export default function MakeMore() {
  const [content1, setContent1] = useState(false);
  const [content2, setContent2] = useState(false);
  const [content3, setContent3] = useState(false);
  return (
    <section className={classes.make}>
      <h1>Make more sales with the right tools</h1>
      <div className={classes.wrapper}>
        <div className={classes.wrap}>
          <div
            onClick={() => setContent1((prev) => !prev)}
            className={classes.content}
          >
            <div className={classes.title}>
              <h3>Create Landing pages</h3>
              <span>
                {content1 ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </span>
            </div>
            {content1 && (
              <p>
                Affils helps you to plan and manage your product/service sales.
              </p>
            )}
          </div>
          <div
            onClick={() => setContent2((prev) => !prev)}
            className={classes.content}
          >
            <div className={classes.title}>
              <h3>Track your affliliates</h3>
              <span>
                {content2 ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </span>
            </div>
            {content2 && (
              <p>
                Affils helps you to plan and manage your product/service sales.
              </p>
            )}
          </div>
          <div
            onClick={() => setContent3((prev) => !prev)}
            className={classes.content}
          >
            <div className={classes.title}>
              <h3>Manage your sales</h3>
              <span>
                {content3 ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </span>
            </div>
            {content3 && (
              <p>
                Affils helps you to plan and manage your product/service sales.
              </p>
            )}
          </div>
          <button>use affils</button>
        </div>
        <div className={classes.wrap}>
          <img alt="make more" src={image} />
        </div>
      </div>
    </section>
  );
}
