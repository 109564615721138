import React from "react";

import Vector from "../../images/Auth/sign__vector.svg";

import "../../styles/Auth/SideLogin.css";

const SideLogin = () => {
  return (
    <div className="side__login">
      <div className="logo">
        <img src={Vector} alt="Vector avatar" width={250} height={221} />
      </div>
      <div className="side__body">
        <h3>Keep On Growing</h3>
        <p>Be Boundless!</p>
      </div>
    </div>
  );
};

export default SideLogin;
