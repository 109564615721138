import React from "react";
import image from "../../../images/groundforce-dashboard/Rectangle 1.png";

export default function Detail() {
  return (
    <div className="w-full flex flex-col lg:h-[159rem] border rounded-lg">
      <div className="w-full">
        <img className="img h-[14rem]" alt="product" src={image} />
      </div>
      <div className="w-full p-4 flex border-b-2 flex-col gap-2">
        <p className=" text-base lg:text-[1.4rem] font-semibold capitalize text-[#101828]">
          Product name
        </p>
        <p className="text-[#667085] font-medium text-sm lg:text-[1.2rem]">
          Digital Product
        </p>
      </div>
      <div className="w-full p-4 flex border-b-2 flex-col gap-2">
        <p className="text-base lg:text-[1.4rem] font-semibold capitalize text-[#101828]">
          Product Type
        </p>
        <p className="text-[#667085] font-medium text-sm lg:text-[1.2rem]">
          Digital Product
        </p>
      </div>
      <div className="w-full p-4 flex border-b-2 flex-col gap-2">
        <p className="text-base lg:text-[1.4rem] font-semibold capitalize text-[#101828]">
          Product category
        </p>
        <p className="text-[#667085] font-medium text-sm lg:text-[1.2rem]">
          Digital Product
        </p>
      </div>
      <div className="w-full p-4 flex border-b-2 flex-col gap-2">
        <p className="text-base lg:text-[1.4rem] font-semibold capitalize text-[#101828]">
          Product description
        </p>
        <p className="text-[#667085] font-medium text-sm lg:text-[1.2rem]">
          Digital Product
        </p>
      </div>
    </div>
  );
}
