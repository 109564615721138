import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function ProductDetailSubHeader() {
  return (
    <div className="px-4 lg:px-20 py-4 flex flex-col  lg:flex-row items-start  gap-4 lg:items-center justify-between">
      <div className="flex flex-col gap-2 lg:gap-1">
        <p className="text-sm font-semibold capitalize text-[#A0A0A0]">
          Products / <span className="text-[#1C1B1F]">Product 1 Overview</span>
        </p>
        <h1 className="text-[1.2rem] lg:text-[2.5rem] font-bold capitalize text-[#1C1B1F]">
          Product 1 Overview
        </h1>
      </div>
      <div className="flex items-center gap-4">
        <button className="flex items-center bg-[#0D5BA1] py-2 px-4 rounded-lg gap-2">
          <span className="stat-arrow text-white">
            <FiEdit2 />
          </span>
          <span className="stat-arrow text-white capitalize">edit product</span>
        </button>
        <span className="stat-arrow p-2 rounded-lg border">
          <BsThreeDotsVertical />
        </span>
      </div>
    </div>
  );
}
