import React from "react";
import classes from "../Groundforce/groundforce.module.css";
import { useNavigate } from "react-router-dom";

export default function Expand({ text, title, style, link }) {
  const navigate = useNavigate();
  return (
    <section className={classes.expand} style={style}>
      <h1>{text}</h1>
      <button
        onClick={() => navigate(`/${link}`)}
        className="p-4 bg-transparent border border-[#222] capitalize rounded-lg hover:opacity-80 hover:shadow-lg transition-all duration-300"
      >
        use {title}
      </button>
    </section>
  );
}
