import React from "react";

export default function Select({ options, label, onChange, value }) {
  return (
    <div className="flex items-start gap-2 flex-col w-full">
      <label
        htmlFor={label}
        className="text-base font-medium text-[#333] capitalize"
      >
        {label}
      </label>

      <select
        name="country"
        value={value}
        className="p-3 outline-none border w-full border-[#ccc] rounded-lg text-black"
        onChange={onChange}
      >
        <option value="">Select {label}</option>
        {options.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
    </div>
  );
}
