import React from "react";

import "../../styles/Boundless.css";

import groundforce from "../../images/Boundless/Rectangle 8 (1).png";
import affils from "../../images/Boundless/Rectangle 18 (1).png";
import { useNavigate } from "react-router-dom";

const CostMarketing = () => {
  const navigate = useNavigate()
  return (
    <div className='lg:w-[80%] w-[90%] py-20 mx-auto flex flex-col gap-4'>
      <div className="marketing__head">
        <h3 className='text-4xl font-bold text-center capitalize' style={{ textAlign: "center" }}>
          Do Cost-effective Marketing and Get Better Sales
        </h3>
      </div>

      <div className="marketing__products">
        {/* Groundforce */}
        <div className="groundforce">
          <div className="groundforce__image">
            <img src={groundforce} alt="Groundforce avatar" />
          </div>
          <div className="groundforce__description">
            <h5>Groundforce®</h5>
            <p>
              Get, Track and manage verified sales and distribution agents. Map
              your sales channels, finances, sales projections and more, for
              maximum profit.
            </p>
            <button onClick={()=>navigate('/groundforce')}>Try Groundforce</button>
          </div>
        </div>

        {/* Affils */}
        <div className="affils">
          <div className="affils__description">
            <h5>Affils® </h5>
            <p>
              Get goal-oriented Affiliates and Set up affiliate sales for your
              products. Create unique pages, create products, generate links,
              track all online sales channels for better targeting with or
              without a website
            </p>
            <button onClick={()=>navigate('/affil')}>Try Affils</button>
          </div>
          <div className="affils__image">
            <img src={affils} alt="Affils avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostMarketing;
