import React from "react";
import logo from "../../images/groundforce-logo.png";
import { NavLink } from "react-router-dom";

import { AiOutlineMenu } from "react-icons/ai";

import mail from "../../images/groundforce-dashboard/mail.png";
import bell from "../../images/groundforce-dashboard/notifications.png";
import calender from "../../images/groundforce-dashboard/calendar_month.png";
import widget from "../../images/groundforce-dashboard/widgets.png";
import user from "../../images/groundforce-dashboard/Ellipse 1.png";
import { Fragment } from "react";
import MobileMenu from "./MobileMenu";
import { useState } from "react";
export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <Fragment>
      <header className="w-full relative border-b-2 px-4 lg:px-20 py-6 flex items-center justify-between">
        <div className="flex items-center gap-8">
          <div className="w-[11rem]">
            <img className="img" alt="logo" src={logo} />
          </div>
          <div className="hidden  lg:flex items-center gap-4">
            <NavLink
              to="/groundforce-dashboard"
              className={(navData) => (navData.isActive ? "active" : "menu")}
            >
              dashboard
            </NavLink>
            <NavLink
              to="/groundforce-products"
              className={(navData) => (navData.isActive ? "active" : "menu")}
            >
              products
            </NavLink>
            <NavLink
              to="/groundforce-activities"
              className={(navData) => (navData.isActive ? "active" : "menu")}
            >
              activities
            </NavLink>
            <NavLink
              to="/groundforce-transactions"
              className={(navData) => (navData.isActive ? "active" : "menu")}
            >
              transactions
            </NavLink>
          </div>
        </div>
        <div className="hidden lg:flex items-center gap-4">
          <div className="w-[1.5rem]">
            <img className="img h-[1rem]" alt="logo" src={mail} />
          </div>
          <div className="w-[1rem]">
            <img className="img h-[1.5rem]" alt="logo" src={bell} />
          </div>
          <div className="w-[1.2rem]">
            <img className="img h-[1.5rem]" alt="logo" src={calender} />
          </div>
          <div className="w-[1.5rem]">
            <img className="img h-[1.5rem]" alt="logo" src={widget} />
          </div>
          <div className="w-[2.62rem]">
            <img
              className="img h-[2.62rem] rounded-full"
              alt="logo"
              src={user}
            />
          </div>
        </div>
        <div className="flex items-center gap-3 lg:hidden">
          <div className="w-[1.7rem]">
            <img
              className="img h-[1.7rem] rounded-full"
              alt="logo"
              src={user}
            />
          </div>
          <span
            onClick={() => setShowMenu(true)}
            className="text-xl cursor-pointer "
          >
            <AiOutlineMenu />
          </span>
        </div>
      </header>
      {showMenu && <MobileMenu setShowMenu={setShowMenu} />}
    </Fragment>
  );
}
