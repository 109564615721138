import React from "react";
import classes from "./resources.module.css";

import { BsFacebook } from "react-icons/bs";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

export default function Socials() {
  return (
    <div className={classes.socials}>
      <div className={classes.social}>
        <h1>Social</h1>
        <p>
          Like, Comment, Tweet, Share ,Post ... We are with you all the way.
        </p>
        <div className={classes.link}>
          <Link to="">
            <BsFacebook />
          </Link>
          <Link to="">
            <AiOutlineTwitter />
          </Link>
          <Link to="">
            <AiFillInstagram />
          </Link>
          <Link to="">
            <AiFillLinkedin />
          </Link>
          <Link to="">
            <RiWhatsappFill />
          </Link>
        </div>
      </div>

      <div className={classes.faq}>
        <h1>FAQs</h1>
        <p>
          We understand that you have hundreds of questions. View some of the
          answers here
        </p>
        <Link to="" className={classes.action}>
          <p>
            Answer your questions <BsArrowRight />
          </p>
        </Link>
      </div>

      <div className={classes.newsletter}>
        <h1>Newsletter</h1>
        <p>
          Get updated with the latest updates, upgrades, news and information,{" "}
        </p>
        <form>
          <input placeholder="Enter email address" type="email" />
          <button>submit</button>
        </form>
      </div>
    </div>
  );
}
