import React from "react";
import { BsArrowRight } from "react-icons/bs";
import CustomerTitle from "./CustomerTitle";
import CustomerList from "./CustomerList";

export default function MyCustomers() {
  return (
    <div className="w-full border flex flex-col gap-4 lg:gap-0 rounded-lg p-4">
      <div className="w-full flex items-center justify-between">
        <h1 className="text-[#101828] font-medium text-[1.2rem] capitalize">
          My Customers
        </h1>
        <div className="flex items-center gap-2">
          <span className="text-base font-normal text-[#667085]">View All</span>
          <span className="stat-arrow">
            <BsArrowRight />
          </span>
        </div>
      </div>
      <div className="w-full  lg:p-4">
        <CustomerTitle />
        <CustomerList />
      </div>
    </div>
  );
}
