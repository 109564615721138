import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import ImageComponent from "../UI/Image";
import img1 from "../../images/p1.png";
import img2 from "../../images/p2.png";
import img3 from "../../images/p3.png";

export default function IncubatePartners() {
  return (
    <Flex
      direction="column"
      my="5rem"
      p="2rem"
      borderRadius="0.75rem"
      gap="1rem"
      w="80%"
      bg="#FAF9FD"
      mx="auto"
    >
      <Heading
        textAlign="center"
        fontSize="20px"
        color="#667085"
        fontWeight="medium"
        as="h2"
      >
        Companies we have worked with
      </Heading>
      <Flex align="center" justify="center" gap="2rem">
        <Box w="20%">
          <ImageComponent alt="incubate" src={img1} />
        </Box>
        <Box w="20%">
          <ImageComponent alt="incubate" src={img2} />
        </Box>
        <Box w="20%">
          <ImageComponent alt="incubate" src={img3} />
        </Box>
      </Flex>
    </Flex>
  );
}
