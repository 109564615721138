import React, { Fragment } from "react";
import ManageYourBusiness from "../Groundforce/ManageYourBusiness";
import spHero from "../../images/SupportPro/Rectangle 5.png";
import spHerosmall from "../../images/SupportPro/Rectangle 5 (1).png";
import SImage from "../../images/SupportPro/image 8.png";
import EsImage from "../../images/SupportPro/image 8.png";
import FsImage from "../../images/SupportPro/image 10.png";
import UImage from "../../images/SupportPro/image 10.png";
import Footer from "../Footer";
import Expand from "../UI/Expand";
import FAQs from "../UI/FAQs";
import Everything from "./Everything";
import Experienced from "./Experienced";
import Take from "./Take";
import Mentoring from "./Mentoring";
import Navbar from "../Navbar";
import ReusableSupport from "./ReusableSupport";
export default function SupportPro() {
  return (
    <Fragment>
      <Navbar style={{ backgroundColor: "#F2F2FF" }} />
      <ManageYourBusiness
        title="Get the right tools and people with supportpro"
        text="Supportpro gives all the Online, Offline and human resources you need to grow from 1 to 100."
        image1={spHero}
        image2={spHerosmall}
        btntext="use supportpro"
        style={{ backgroundColor: "#F2F2FF" }}
      />
      <Everything />
      <ReusableSupport
        text="Hire Experienced  Professionals"
        text2="Gain new knowledge, insights and skills from experienced profrssionals to help build your organisation or business.."
        img1={SImage}
        img2={EsImage}
      />
      <Mentoring />
      <ReusableSupport
        text="Go to the next level in Marketing and Business Development"
        text2="Build your business, increase visibility and reach potential customers/clients everywhere."
        img1={UImage}
        img2={FsImage}
        style={{
          display: "flex",
          // flexDirection: "row-reverse",
        }}
      />
      <Take />
      <FAQs />
      <Expand
        text="Reach your costumers"
        title="supportpro"
        style={{ backgroundColor: "#F2F2FF" }}
      />
      <Footer />
    </Fragment>
  );
}
