import React from "react";

// Components
import Navbar from "../components/Navbar";
import Hero from "../components/About/Hero";
// Styles
import "../styles/About.css";
import MainAbout from "../components/About/MainAbout";
import Story from "../components/About/Story";
import Footer from "../components/Footer";
// import LearnCraze from '../components/HomeComponents/LearnCraze'
// import TestedAndTrusted from '../components/HomeComponents/TestedAndTrusted'
import TrustedBrands from "../components/HomeComponents/TrustedBrands";
import CrazySales from "../components/HomeComponents/CrazySales";
// import Expand from "../components/UI/Expand";

const About = () => {
  return (
    <>
      <div>
        <Navbar />
        <div className="about__hero">
          <div className="hero__section">
            <Hero />
          </div>
          <MainAbout />
          <Story />
        </div>
      </div>
      <TrustedBrands />
      <CrazySales />
      <Footer />
    </>
  );
};

export default About;
