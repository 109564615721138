import React from "react";
import classes from "./incubate.module.css";
import business from "../../images/Incubate/image 6.png";
import businesssmall from "../../images/Incubate/image 6 (1).png";
const Business = () => {
  return (
    <div className="w-[90%] lg:w-[80%] mx-auto flex flex-col md:flex-row-reverse gap-4 pt-6 lg:gap-0 lg:flex-row-reverse">
      <div className="w-[90%] lg:h-[420px] p-4 lg:w-[55%] mx-auto items-center justify-center  flex flex-col gap-3">
        <p className="text-[30px] font-bold text-center text-black">
          Incubate for Business
        </p>
        <h2 className="text-[20px] font-medium text-center text-black">
          Upskill your People, Grow your Business
        </h2>
        <p className="text-center w-[80%] mx-auto">
          Boost your Company Growth; Upskill your Marketing, Sales, Customer
          Success, Business Development and Growth Talents
        </p>
        <button className="p-3 rounded-lg hover:opacity-80 hover:shadow-lg text-white font-bold bg-[#0d5ba1]">
          Register your Teams
        </button>
      </div>
      <div className={classes.image}>
        <img
          alt="business description"
          src={business}
          className={classes.largeScreen}
        />
        <img
          alt="business description"
          src={businesssmall}
          className={classes.smallScreen}
        />
      </div>
    </div>
  );
};

export default Business;
