import React from "react";
import classes from "./reccords.module.css";
import { TfiWallet } from "react-icons/tfi";
import { FiCreditCard } from "react-icons/fi";
import { MdOutlineInventory2 } from "react-icons/md";
import { TbCashBanknote } from "react-icons/tb";
import { MdOutlineSavings } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BiTrendingUp } from "react-icons/bi";
import { BiLike } from "react-icons/bi";

function ReusablePowerful({ title, text, icon }) {
  return (
    <div className="lg:w-[30%] my-3 w-[45%] mx-auto flex items-start flex-col gap-2">
      <span className="text-xl text-[#0D5BA1] lg:text-[40px]">{icon}</span>
      <h3 className="text-base  lg:text-xl font-bold text-black text-start">
        {title}
      </h3>
      <p className="text-base  font-normal text-start w-full lg:w-[80%]">
        {text}
      </p>
    </div>
  );
}

const Powerful = () => {
  return (
    <section className={classes.powerful}>
      <h1>Powerful accounting tools you can use Online or Offline</h1>
      <div className="lg:w-[80%] w-[90%] mx-auto flex items-start lg:items-center justify-start flex-wrap lg:justify-between">
        <ReusablePowerful
          title="Manage Cashflow and Expenses"
          text="Never be afraid of tomorrow with your cashflow organized in one
        place."
          icon={<TfiWallet />}
        />

        <ReusablePowerful
          title="Digital Payments"
          text="Send and recieve payments digitally from everywhere and anywhere."
          icon={<FiCreditCard />}
        />

        <ReusablePowerful
          title="Manage Inventory"
          text="Stay on top of your orders and quantities while managing your
          inventory in real-time."
          icon={<MdOutlineInventory2 />}
        />

        <ReusablePowerful
          title="Acquire loans"
          text="Grow and scale your business with loans with the lowest interest
        rates"
          icon={<TbCashBanknote />}
        />

        <ReusablePowerful
          title="Manage Debts"
          text="Never lose track of debts or any pending payments with Reccords."
          icon={<MdOutlineSavings />}
        />

        <ReusablePowerful
          title="Insights and Reports"
          text="See how your business is doing (and will do) with our well detailed
        reports."
          icon={<HiOutlineDocumentText />}
        />

        <ReusablePowerful
          title="Business Consultations"
          text="Solve your challenges by getting dirctions from seasoned consultants
        and mentors"
          icon={<BiTrendingUp />}
        />

        <ReusablePowerful
          title="Connect with salesplat"
          text="Connect your account to other salesplat products (e.g Distribute)
        and manage your operations in one place."
          icon={<BiLike />}
        />

        <ReusablePowerful
          title="On your Mark, Set, Go"
          text="Connect your account to other salesplat products (e.g Distribute)
      and manage your operations in one place."
          icon={<BiLike />}
        />
      </div>
      <div className="lg:w-[40%] flex items-center justify-center  mx-auto w-[90%]">
        <button>use Recccords</button>
      </div>
    </section>
  );
};

export default Powerful;
