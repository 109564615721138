import React from "react";
import "../../styles/LearnCraze.css";
const CrazySales = () => {
  return (
    <div>
      <div className="craze container mb-5">
        <h3>Make Crazy Sales</h3>
        <button>Get Started</button>
      </div>
    </div>
  );
};

export default CrazySales;
