import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { FiArrowUp } from "react-icons/fi";

import line from "../../../images/groundforce-dashboard/Line.png";

export default function TotalCustomers() {
  return (
    <div className="stat-wrap lg:w-[50%]">
      <div className="stat-header">
        <h1 className="stat-title">Total customers</h1>
        <span className="stat-arrow text-black">
          <FiArrowUpRight />
        </span>
      </div>
      <div className="w-full flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <h1 className="stat-value">2,420</h1>
          <div className="flex items-center gap-2">
            <span className="stat-arrow text-[#12B76A]">
              <FiArrowUp />
            </span>
            <span className="text-sm font-normal text-[#12B76A]">
              40% vs last month
            </span>
          </div>
        </div>
        <div className="w-[40%]">
          <img className="img" src={line} alt="stat" />
        </div>
      </div>
    </div>
  );
}
