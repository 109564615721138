import React from "react";
import Navbar from "../Navbar";
import { Link } from 'react-router-dom'

import "../../styles/Contact.css";
import CrazySales from "../HomeComponents/CrazySales";
import Footer from "../Footer";

import MapPin from "../../images/Contact/map-pin.svg";
import Phone from "../../images/Contact/phone.svg";
import Mail from "../../images/Contact/mail.svg";

// Social
import Facebook from "../../images/Contact/Facebook.svg";
import Twitter from "../../images/Contact/Twitter.svg";
import Instagram from "../../images/Contact/Instagram.svg";
import Linkedin from "../../images/Contact/LinkedIn.svg";
import WhatsApp from "../../images/Footer/WhatsApp.svg";
import Arrow from "../../images/Footer/Arrow.svg";

const Contact = () => {
  return (
    <div className="contact__section">
      <Navbar />
      <div className="contact__hero">
        <h1>
          Don’t worry... <br /> You can tell us about It
        </h1>
        <div className="contact__cards">
          <div className="contact__card">
            <img src={MapPin} alt="Map Pin Avatar" />
            <p>Suite 8, Block LO, Industrial Park, Akure, Nigeria </p>
          </div>
          <div className="contact__card">
            <img src={Phone} alt="Phone Avatar" />
            <p>+2347057799457</p>
            <p>+2349066259837</p>
          </div>
          <div className="contact__card">
            <img src={Mail} alt="Mail Avatar" />
            <p>info@salesplat.com</p>
          </div>
        </div>
      </div>

      <div className="contact__forms">
        <div className="contact__form">
          <h3>Contact</h3>
          <p>
            We don’t bite.... you know, Let us know what’s on your mind and
            expect our reply soonest.
          </p>
          <form>
            <div className="form__area">
              <label htmlFor="name">Name</label> <br />
              <input type="text" name="Name" />
            </div>
            <div className="form__area">
              <label htmlFor="email">Email</label> <br />
              <input type="email" name="email" />
            </div>
            <div className="form__area">
              <label htmlFor="message">Tell us how we can help you</label> <br />
              <textarea name="message" cols="30" rows="10"></textarea>
            </div>
            <button>Send Message</button>
          </form>
        </div>
        <div className="social__contact">
          <div className="social">
            <h3>Social</h3>
            <p>
              Like, Comment, Tweet, Share ,Post ... We are with you all the way.
            </p>
            <div className="social__links">
              <img src={Facebook} alt="Facebook avatar" />
              <img src={Twitter} alt="Twitter avatar" />
              <img src={Instagram} alt="Insta avatar" />
              <img src={Linkedin} alt="Linkedin Avatar" />
              <img src={WhatsApp} alt="WhatsApp Avatar" />
            </div>
          </div>
          <div className="faqs">
            <h3>FAQs</h3>
            <p>
              We understand that you have hundreds of questions. View some of
              the answers hereF
            </p>
            <div className="faqs__answer">
              <p>Ask your questions</p>
              <Link to="/faqs"><img src={Arrow} alt="Arrow avatar" /></Link>
            </div>
          </div>
          <div className="newsletter">
            <h3>Newsletter</h3>
            <p>
              Get updated with the latest updates, upgrades, news and
              information,{" "}
            </p>
            <input type="email" name="email" placeholder="Email Address" />
            <br />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
      <CrazySales />
      <Footer />
    </div>
  );
};

export default Contact;
