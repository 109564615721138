import React from "react";
import { NavLink } from "react-router-dom";

export default function ProductDetailHeader() {
  return (
    <div className="px-4 hidden  lg:px-20 py-6 lg:flex flex-col border-b-2 lg:flex-row items-start justify-start gap-4 lg:items-center">
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "menu")}
        to="/groundforce-products/overview"
      >
        overview
      </NavLink>
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "menu")}
        to="/analytics"
      >
        analytics
      </NavLink>
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "menu")}
        to="/groundforce-products/customers"
      >
        customers
      </NavLink>
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "menu")}
        to="/groundforce-products/tasks"
      >
        tasks
      </NavLink>
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "menu")}
        to="/groundforce-products/agents"
      >
        agents
      </NavLink>
      <NavLink
        className={(navData) => (navData.isActive ? "active" : "menu")}
        to="/groundforce-products/reports"
      >
        reports
      </NavLink>
    </div>
  );
}
