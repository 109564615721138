import React from "react";
import Header from "../Header";
import ProductDetailHeader from "./ProductDetailHeader";
import ProductDetailSubHeader from "./ProductDetailSubHeader";
import ProductDetailCenter from "./ProductDetailCenter";

export default function ProductDetail() {
  return (
    <div className="w-full">
      <Header />
      <ProductDetailHeader />
      <ProductDetailSubHeader />
      <ProductDetailCenter />
    </div>
  );
}
